import { Page } from "@/layout/Page";
import {
  Document,
  Page as PDFPage,
  PDFDownloadLink,
  PDFViewer,
  Text,
  View,
} from "@react-pdf/renderer";
import { Button, Stack } from "@mui/material";
import { useQ } from "@/hooks/useQ";
import { project } from "@/queries";
import { useT } from "@/hooks/useT";
import { useParams } from "react-router-dom";
import { styles, PDFKit } from "@/base/pdf";
import { DatePretty } from "@/base/DatePretty";
import Html from "react-pdf-html";
import { BriefPdfDocument } from "./BriefPdf";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import React from "react";
import { useFlag } from "@/hooks/useFlag";

export const PIDPdfDocument = ({
  t,
  projectData,
  qualityApproach,
  changeApproach,
  riskApproach,
  stages,
  roleData = [],
  businessCase,
}) => {
  const { H1, H2, H3, H4, BODY, HR, HTML } = PDFKit;

  return (
    <Document>
      <PDFPage size="A4" style={styles.page}>
        <H1>Project Initiation Document</H1>
        <H2>
          {projectData?.name} -&nbsp;
          <DatePretty
            format={"ll"}
            date={projectData?.date ? projectData.date : new Date()}
          />
        </H2>
        <View style={styles.previewBox}>
          <Text style={styles.body}>Preview</Text>
        </View>
        <BriefPdfDocument
          t={t}
          projectData={projectData}
          roleData={roleData}
          title={false}
          businessCase={businessCase}
        />
        {!!Object.keys(qualityApproach ?? {}).length && (
          <View style={styles.section}>
            <H3>{t("project.quality.approach.title")}</H3>
            <HR />
            {[
              "introduction",
              "process",
              "tools",
              "records",
              "reporting",
              "timing",
              "roles",
            ].map((key) =>
              qualityApproach?.[key] ? (
                <>
                  <H4>{t(`project.quality.approach.fields.${key}.title`)}</H4>
                  <View style={{ marginBottom: 10 }}>
                    <Html
                      style={{
                        fontSize: 12,
                      }}
                      key={key}
                    >
                      {qualityApproach?.[key]}
                    </Html>
                  </View>
                </>
              ) : null,
            )}
          </View>
        )}
        {!!riskApproach?.approach &&
          !!Object.keys(riskApproach?.approach ?? {}).length && (
            <View style={styles.section}>
              <H3>{t("project.risk.approach.title")}</H3>
              <HR />
              {[
                "process",
                "tools",
                "reporting",
                "timing",
                "roles",
                "tolerance",
                "budget",
              ].map((key) =>
                riskApproach?.approach?.[key] ? (
                  <>
                    <H4>{t(`project.risk.approach.fields.${key}.title`)}</H4>
                    <View style={{ marginBottom: 10 }}>
                      <Html
                        style={{
                          fontSize: 12,
                        }}
                        key={key}
                      >
                        {riskApproach?.approach?.[key]}
                      </Html>
                    </View>
                  </>
                ) : null,
              )}
            </View>
          )}
        {!!changeApproach.approach &&
          !!Object.keys(changeApproach?.approach ?? {}).length && (
            <View style={styles.section}>
              <H3>{t("project.change.approach.title")}</H3>
              <HR />
              {[
                "process",
                "reporting",
                "roles",
                "scales",
                "timing",
                "tools",
              ].map((key) =>
                changeApproach?.approach?.[key] ? (
                  <>
                    <H4>{t(`project.change.approach.fields.${key}.title`)}</H4>
                    <View style={{ marginBottom: 10 }}>
                      <Html
                        style={{
                          fontSize: 12,
                        }}
                        key={key}
                      >
                        {changeApproach?.approach?.[key]}
                      </Html>
                    </View>
                  </>
                ) : null,
              )}
            </View>
          )}
        {!!Object.keys(projectData?.communicationApproach ?? {}).length && (
          <View style={styles.section}>
            <H3>{t("project.organization.approach.title")}</H3>
            <HR />
            {["process", "tools"].map((key) =>
              projectData?.communicationApproach?.[key] ? (
                <>
                  <H4>
                    {t(`project.organization.approach.fields.${key}.title`)}
                  </H4>
                  <View style={{ marginBottom: 10 }}>
                    <Html
                      style={{
                        fontSize: 12,
                      }}
                      key={key}
                    >
                      {projectData?.communicationApproach?.[key]}
                    </Html>
                  </View>
                </>
              ) : null,
            )}
          </View>
        )}
        {!!stages?.length ? (
          <View style={styles.section}>
            <H3>{t("project.projectInitiationDocument.projectPlan.title")}</H3>
            <HR />
            <HTML>
              {`<table>
              <thead>
                <tr style="background-color: #f3f4f6; border: 1px solid #e5e7db;">
                  <th style="padding: 8px; text-transform: uppercase; font-size: 7px; color: #6b7280">${t(
                    "project.plans.schedule.headings.stage",
                  )}</th>
                  <th style="padding: 8px; text-transform: uppercase; font-size: 7px; color: #6b7280">${t(
                    "project.plans.schedule.headings.time",
                  )}<br />${t("project.plans.schedule.headings.start")}</th>
                  <th style="padding: 8px; text-transform: uppercase; font-size: 7px; color: #6b7280"><br />${t(
                    "project.plans.schedule.headings.end",
                  )}</th>
                  <th style="padding: 8px; text-transform: uppercase; font-size: 7px; color: #6b7280"><br />${t(
                    "project.plans.schedule.headings.tolerance",
                  )}</th>
                  <th style="padding: 8px; text-transform: uppercase; font-size: 7px; color: #6b7280">${t(
                    "project.plans.schedule.headings.cost",
                  )}<br />${t("project.plans.schedule.headings.hours")}</th>
                  <th style="padding: 8px; text-transform: uppercase; font-size: 7px; color: #6b7280"><br />${t(
                    "project.plans.schedule.headings.expenses",
                  )}</th>
                  <th style="padding: 8px; text-transform: uppercase; font-size: 7px; color: #6b7280"><br />${t(
                    "project.plans.schedule.headings.investments",
                  )}</th>
                  <th style="padding: 8px; text-transform: uppercase; font-size: 7px; color: #6b7280"><br />${t(
                    "project.plans.schedule.headings.sum",
                  )}</th>
                  <th style="padding: 8px; text-transform: uppercase; font-size: 7px; color: #6b7280"><br />${t(
                    "project.plans.schedule.headings.tolerance",
                  )}</th>
                </tr>
              </thead>
              <tbody>
                ${(stages ?? [])
                  .map(
                    (stage) =>
                      `<tr style="font-size: 8px">
                    <td style="padding: 8px; border: 1px solid #e5e7db;">${
                      stage.name ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      stage?.time?.start ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      stage?.time?.end ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      stage.timeTolerance
                    }
                      ${
                        stage.timeToleranceUnit
                          ? t(
                              "project.plans.stage.timeTolerance.units." +
                                stage.timeToleranceUnit,
                            ).toLowerCase()
                          : ""
                      }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      stage?.cost?.hours ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      stage?.cost?.expense ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      stage?.cost?.investment ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      stage?.cost?.sum ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      stage?.cost?.tolerance ?? ""
                    }</td>
                  </tr>`,
                  )
                  .join("")}
              </tbody>
            </table>`}
            </HTML>
          </View>
        ) : null}
      </PDFPage>
    </Document>
  );
};

function getVersion(data, version) {
  return !!data?.history
    ? data.history.find(
        (x) => `${x.majorVersion}.${x.minorVersion}` === version,
      )
    : null;
}

export const PIDPdf = () => {
  const { t } = useT();
  const { id = "", version = null } = useParams();
  const useOptimizedProject = useFlag("project.optimize.project");
  const {
    isLoading,
    data: projectData = {},
    refetch: refetchProject,
  } = useQ(`project-${id}`, () => project.single({ id }));

  const { data: history = [] } = useQ(`project-${id}-history`, () =>
    useOptimizedProject ? project.history({ id }) : () => [],
  );

  const historicReport = getVersion(
    useOptimizedProject ? { ...projectData, history: history } : projectData,
    version,
  );
  const historicProjectData = {
    name: projectData?.name,
    date: historicReport?.date,
    background: historicReport?.definition?.background?.initiated,
    timing: historicReport?.definition?.background?.timing,
    bigPicture: historicReport?.definition?.background?.bigPicture,
    benefitsDescription: historicReport?.definition?.objective?.benefits,
    objectivesSummary: historicReport?.definition?.objective?.summary,
    projectScope:
      historicReport?.projectProductDescription !== undefined
        ? historicReport?.projectProductDescription
        : historicReport?.definition?.scope?.items,
    constraints: historicReport?.definition?.constraints,
    assumptions: historicReport?.definition?.assumptions,
    stakeholderDescription: historicReport?.definition?.stakeholders,
    approach: historicReport?.approach,
    communicationApproach: historicReport?.communicationManagementApproach,
  };

  const { data: roles = [], refetch } = useQ(`project-${id}-roles`, () =>
    project.roles({ id }),
  );

  const { data: qualityApproach = {} } = useQ(
    `project-${id}-quality-approach`,
    () => project.quality.approach({ projectId: id }),
  );

  const { data: riskApproach = {} } = useQ(`project-${id}-risk-approach`, () =>
    project.riskApproach({ id }),
  );

  const { data: changeApproach = {} } = useQ(
    `project-${id}-change-approach`,
    () => project.changeApproach({ id }),
  );

  const { data: projectPlanData = {}, refetch: refetchProjectPlans } = useQ(
    `project-${id}-plans`,
    () => project.plans({ id }),
  );

  const { data: businessCaseData = {} } = useQ(
    `project-${projectData?.id}-businessCase`,
    () => project.businessCase({ id: projectData?.id }),
  );

  const data = version ? historicProjectData : projectData;
  const businessCase = version ? historicReport.businessCase : businessCaseData;
  return (
    <Page title={"PID PDF"}>
      <Stack py={2} height="100%" spacing={2}>
        {!!data && (
          <PDFDownloadLink
            document={
              <PIDPdfDocument
                t={t}
                projectData={data}
                roleData={version ? historicReport?.organization : roles}
                qualityApproach={
                  version
                    ? historicReport?.qualityManagementApproach
                    : qualityApproach
                }
                riskApproach={
                  version
                    ? historicReport?.riskManagementApproach
                    : riskApproach
                }
                changeApproach={
                  version
                    ? historicReport?.changeControlApproach
                    : changeApproach
                }
                stages={
                  version ? historicReport?.plan : projectPlanData?.stages ?? []
                }
                businessCase={businessCase}
              />
            }
            fileName={`PID_${data?.name?.replace?.(/ /g, "")}_${moment(
              data?.date ?? new Date(),
            ).format("YYYY-MM-DD")}`}
          >
            <Button variant="contained" endIcon={<DownloadIcon />}>
              Download PDF
            </Button>
          </PDFDownloadLink>
        )}
        <PDFViewer
          style={{
            width: "100%",
            height: "100%",
          }}
          showToolbar={false}
        >
          <PIDPdfDocument
            t={t}
            projectData={version ? historicProjectData : projectData}
            roleData={version ? historicReport?.organization : roles}
            qualityApproach={
              version
                ? historicReport?.qualityManagementApproach
                : qualityApproach
            }
            riskApproach={
              version ? historicReport?.riskManagementApproach : riskApproach
            }
            changeApproach={
              version ? historicReport?.changeControlApproach : changeApproach
            }
            stages={
              version ? historicReport?.plan : projectPlanData?.stages ?? []
            }
            businessCase={businessCase}
          />
        </PDFViewer>
      </Stack>
    </Page>
  );
};
