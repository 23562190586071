import React, { useState, useRef } from "react";
import { useT } from "../../../hooks/useT";
import { Page } from "../../../layout/Page";
import {
  useParams,
  useLocation,
  useNavigate,
  matchPath,
} from "react-router-dom";
import { project, projectType } from "../../../queries";
import { useQ } from "../../../hooks/useQ";
import { CustomAccordion } from "../../../layout/CustomAccordion";
import { Stack, Typography, Box, Button, Paper } from "@mui/material";
import { DatePretty } from "../../../base/DatePretty";
import {
  DataTable,
  DataTableAutoRows,
  DataTableHeading,
} from "../../../base/table/DataTable";
import { isNil } from "ramda";
import { DisplayContent } from "../../../base/content/DisplayContent";
import { isEmpty } from "ramda";
import { StagePlanForm } from "../Plans";
import { useToast } from "../../../hooks/useToast";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { FormTextField } from "../../../base/form/mui/FormTextField";
import { ProjectDefinition } from "../components/ProjectDefinition";
import { useFlag } from "@/hooks/useFlag";

const ProjectBrief = ({ projectId, projectData, roles, version }) => {
  const { t } = useT();

  const isCurrent = isNil(version);
  const { data: businessCaseData = {} } = useQ(
    `project-${projectId}-businessCase`,
    () => project.businessCase({ id: projectId }),
  );
  const businessCase = isCurrent ? businessCaseData : projectData.businessCase;
  return (
    <Stack spacing={2.5}>
      {projectData &&
      (projectData.background ||
        projectData.timing ||
        projectData.bigPicture ||
        projectData.benefitsDescription ||
        projectData?.objectivesSummary ||
        projectData?.projectScope ||
        projectData.constraints ||
        projectData.assumptions ||
        projectData.stakeholderDescription ||
        projectData.definition) ? (
        <Stack spacing={1}>
          <Typography sx={{ borderBottom: "1px solid #E5E7EB" }} variant="h1">
            {" "}
            {t("project.projectBrief.definition.title")}
          </Typography>

          <ProjectDefinition isCurrent={isCurrent} projectData={projectData} />
        </Stack>
      ) : null}
      {!isEmpty(
        [
          "reasons",
          "doNothing",
          "doMinimal",
          "doSomething",
          "executiveSummary",
          "investmentAppraisal",
          "risks",
        ].filter(
          (key) => businessCase?.[key] !== "" && !isNil(businessCase?.[key]),
        ),
      ) ||
      (businessCase?.benefits && !isEmpty(businessCase?.benefits)) ? (
        <Stack spacing={1}>
          <Typography sx={{ borderBottom: "1px solid #E5E7EB" }} variant="h1">
            {" "}
            {t("project.businessCase.title")}
          </Typography>
          <DisplayContent
            area={"businessCase.addEditForm.fields"}
            data={businessCase ?? {}}
            fields={["reasons", "doNothing", "doMinimal", "doSomething"]}
          />
          {businessCase?.benefits && !isEmpty(businessCase?.benefits) ? (
            <>
              <Typography variant="h2">
                {t("project.projectBrief.businessCase.title")}
              </Typography>
              <CustomAccordion
                tableView
                header={{
                  expanded: false,
                  columns: [
                    {
                      title: "ID",
                      flex: 1,
                      sx: { marginRight: -2 },
                    },
                    {
                      title: t(
                        "project.businessCase.addEditForm.fields.benefits.title.title",
                      ),
                      flex: 3,
                    },
                    {
                      title: t(
                        "project.businessCase.addEditForm.fields.benefits.baseline.title",
                      ),
                      flex: 3,
                    },
                    {
                      title: t(
                        "project.businessCase.addEditForm.fields.benefits.goal.title",
                      ),
                      flex: 3,
                    },
                    {
                      title: t(
                        "project.businessCase.addEditForm.fields.benefits.accountable.title",
                      ),
                      flex: 3,
                    },
                    { title: "", width: 40 },
                  ],
                }}
                rows={[
                  ...(businessCaseData?.benefits ?? []).map((item, index) => ({
                    id: item.id,
                    expanded: false,
                    columns: [
                      {
                        content: (
                          <Typography sx={{ mr: 1 }}>{index + 1}</Typography>
                        ),
                        flex: 1,
                      },
                      {
                        content: <Typography>{item.title}</Typography>,
                        flex: 3,
                        sx: { marginRight: 10 },
                      },
                      {
                        content: <Typography>{item.baseline}</Typography>,
                        flex: 3,
                      },
                      {
                        content: <Typography>{item.goal}</Typography>,
                        flex: 3,
                      },
                      {
                        content: <Typography>{item.accountable}</Typography>,
                        flex: 3,
                        sx: { marginLeft: 5 },
                      },
                    ],
                  })),
                ]}
              />
            </>
          ) : null}
          <DisplayContent
            area={"businessCase.addEditForm.fields"}
            data={isCurrent ? businessCaseData : businessCaseData ?? {}}
            fields={["executiveSummary", "investmentAppraisal", "risks"]}
          />
        </Stack>
      ) : null}
      {projectData?.projectScope?.some((obj) =>
        Object.keys(obj).includes("items"),
      ) ||
      projectData?.projectProductDescription?.some((obj) =>
        Object.keys(obj).includes("items"),
      ) ? (
        <Stack spacing={1}>
          <Typography sx={{ borderBottom: "1px solid #E5E7EB" }} variant="h1">
            {t("project.quality.ppd.title")}
          </Typography>
          {(isCurrent
            ? projectData?.projectScope
            : projectData?.projectProductDescription
          )?.map((scope) => {
            return (
              <Stack>
                <Typography variant="h2">{scope.text}</Typography>
                {scope?.items ? (
                  scope.items.map((item) => {
                    return (
                      <Stack sx={{ marginLeft: "20px" }}>
                        <Typography
                          variant="h2"
                          dangerouslySetInnerHTML={{
                            __html: item.output,
                          }}
                        />
                        <Stack sx={{ marginLeft: "20px" }}>
                          <DisplayContent
                            area="quality.ppd.fields"
                            data={item ?? {}}
                            fields={[
                              "qualityExpectation",
                              "acceptanceCriteria",
                              "acceptanceMethod",
                            ]}
                          />
                        </Stack>
                      </Stack>
                    );
                  })
                ) : (
                  <Typography>{t("project.quality.ppd.noOutput")}</Typography>
                )}
              </Stack>
            );
          })}
        </Stack>
      ) : null}
      {projectData?.approach ? (
        <Stack spacing={1}>
          <Typography sx={{ borderBottom: "1px solid #E5E7EB" }} variant="h1">
            {t("project.projectBrief.projectApproach.title")}
          </Typography>
          <DisplayContent
            area="plans.approach.fields"
            data={projectData.approach ?? {}}
            fields={["methods", "technology", "rollOut"]}
          />
        </Stack>
      ) : null}
      {roles && roles.length > 0 ? (
        <Stack spacing={1}>
          <Typography sx={{ borderBottom: "1px solid #E5E7EB" }} variant="h1">
            {t("project.projectBrief.organization.title")}
          </Typography>
          <OrganizationTable data={roles} />
        </Stack>
      ) : null}
      {/*!isCurrent && roles && !roles.length < 1 ? (
        <Stack spacing={1}>
          <Typography sx={{ borderBottom: "1px solid #E5E7EB" }} variant="h1">
            {t("project.projectBrief.organization.title Historic")}
          </Typography>
          <OrganizationTable data={roles} />
        </Stack>
      ) : null*/}
    </Stack>
  );
};

const OrganizationTable = ({ data = [] }) => {
  const cols = "200px 200px 700px ";
  const { t } = useT();
  const categories = [
    "projectBoard",
    "projectAssurance",
    "projectManagement",
    "deliveryTeam",
  ];

  const roleOrder = [
    "executive",
    "seniorUser",
    "seniorSupplier",
    "businessAssurance",
    "userAssurance",
    "supplierAssurance",
    "projectManager",
    "projectSupport",
    "changeAuthority",
    "stakeholder",
    "teamManager",
    "teamMember",
  ];

  const additionalRoles = [];

  data.forEach((person) => {
    if (person.additionalRoles?.length > 0) {
      person.additionalRoles.forEach((e) => {
        let additionalPerson = {};
        additionalPerson.name = person.name;
        additionalPerson.email = person.email;
        additionalPerson.id = person.id;
        additionalPerson.role = e.role;
        additionalPerson.details = e.details;
        additionalRoles.push(additionalPerson);
      });
    }
  });

  function lowecaseFirstLetter(string) {
    return (string ?? "").charAt(0).toLowerCase() + (string ?? "").slice(1);
  }

  const getCategory = (role) =>
    categories.filter(
      (category) =>
        !t(
          "project.organization." +
            category +
            ".roles." +
            lowecaseFirstLetter(role).replace(" ", "") +
            ".title",
        ).includes("project.organization."),
    )[0];

  const roles = [...data, ...additionalRoles];

  const sortedRoles = isNil(roles)
    ? []
    : roles.filter((item) =>
        roleOrder.includes(
          item.role !== "Excecutive"
            ? lowecaseFirstLetter(item.role).replace(" ", "")
            : "executive",
        ),
      );

  if (sortedRoles !== undefined) {
    sortedRoles.sort((a, b) => a.name.localeCompare(b.name));
    sortedRoles.sort(function (a, b) {
      return (
        roleOrder.indexOf(
          a.role !== "Excecutive"
            ? lowecaseFirstLetter(a.role).replace(" ", "")
            : "executive",
        ) -
        roleOrder.indexOf(
          b.role !== "Excecutive"
            ? lowecaseFirstLetter(b.role).replace(" ", "")
            : "executive",
        )
      );
    });
  }

  return (
    <DataTable>
      <DataTableHeading
        cols={cols}
        headings={[
          t("project.organization.headers.name"),
          t("project.organization.headers.role"),
          t("project.projectBrief.organization.form.description"),
        ]}
      />

      <DataTableAutoRows
        cols={cols}
        data={sortedRoles}
        cells={[
          ({ name }) => name,
          ({ role }) =>
            role !== "Excecutive"
              ? t(
                  "project.organization." +
                    getCategory(role) +
                    ".roles." +
                    lowecaseFirstLetter(role).replace(" ", "") +
                    ".title",
                )
              : t("project.organization.projectBoard.roles.executive.title"),
          ({ details }) => (
            <Typography dangerouslySetInnerHTML={{ __html: details }} />
          ),
        ]}
      />
    </DataTable>
  );
};

const StakeholderForm = ({ stakeholderCommunication }) => {
  return (
    <CustomAccordion
      tableView
      header={{
        columns: [
          { title: "Stakeholder" },
          { title: "Key messages" },
          { title: "Information required" },
          { title: "Information provided" },
        ],
        expanded: false,
      }}
      rows={(stakeholderCommunication ?? []).map((stakeholder) => ({
        expanded: false,
        columns: [
          {
            content: (
              <Typography sx={{ fontWeight: "bold" }}>
                {stakeholder.name}
              </Typography>
            ),
            flex: 1,
            sx: { alignSelf: "flex-start" },
          },
          {
            content: (
              <Typography
                dangerouslySetInnerHTML={{ __html: stakeholder.message }}
              />
            ),
            flex: 1,
            sx: { alignSelf: "flex-start" },
          },
          {
            content: (
              <Typography
                dangerouslySetInnerHTML={{
                  __html: stakeholder.requiredInformation,
                }}
              />
            ),
            flex: 1,
            sx: { alignSelf: "flex-start" },
          },
          {
            content: (
              <Typography
                dangerouslySetInnerHTML={{
                  __html: stakeholder.providedInformation,
                }}
              />
            ),
            flex: 1,
            sx: { alignSelf: "flex-start" },
          },
        ],
      }))}
    />
  );
};

const ApprovalForm = ({ id, isPid }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useT();

  const schema = yup.object().shape({
    approvedBy: yup.string().required(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      approvedBy: "",
    },
  });

  const { handleSubmit, reset } = formData;

  const approveDocument = useMutation(
    isPid ? project.approvePID : project.approveBrief,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Report has been approved");
        navigate(isPid ? `/projects/${id}/pid/` : `/projects/${id}/brief/`);
        window.scrollTo(0, 0);
        setTimeout(function () {
          window.location.reload();
        }, 100);
        reset();
      },
      onError: ({ error }) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error approving report");
      },
    },
  );

  const formRef = useRef(null);

  const scrollToBottom = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  setTimeout(function () {
    scrollToBottom();
  }, 100);

  return (
    <Paper
      sx={{
        padding: "30px 20px",
        display: "block",
        borderColor: "red",
      }}
      ref={formRef}
    >
      <FormProvider {...formData}>
        <form
          onSubmit={handleSubmit((data) => {
            approveDocument.mutate({
              id: id,
              data,
            });
          })}
        >
          <FormTextField
            name={"approvedBy"}
            label={t("project.projectInitiationDocument.approvalForm.title")}
            help={false}
            placeholder={t(
              "project.projectInitiationDocument.approvalForm.placeholder",
            )}
            fullWidth
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button type="submit" variant="contained">
              {t("project.projectInitiationDocument.approvalForm.approve")}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </Paper>
  );
};

function getVersion(data, version, isPid) {
  if (
    isPid &&
    `${data?.pid?.majorVersion}.${data?.pid?.minorVersion}` === version &&
    isNil(
      data?.history?.find(
        (x) => `${x.majorVersion}.${x.minorVersion}` === version,
      ),
    )
  ) {
    return data.pid;
  }
  if (
    !isPid &&
    `1.${data?.brief?.version}` === version &&
    isNil(data?.briefHistory?.find((x) => `1.${x.version}` === version))
  ) {
    return data.brief;
  }
  if (isPid) {
    return !isNil(data?.history)
      ? data.history.find(
          (x) => `${x.majorVersion}.${x.minorVersion}` === version,
        )
      : null;
  } else {
    return !isNil(data?.briefHistory)
      ? data.briefHistory.find((x) => `1.${x.version}` === version)
      : null;
  }
}

export const CombinedProjectReports = () => {
  const useOptimizedProject = useFlag("project.optimize.project");
  const { t } = useT();
  const { id = "", version = null } = useParams();
  const loc = useLocation();

  const match = (path) =>
    matchPath(
      {
        path,
      },
      loc.pathname,
    );

  const isPid = match("/projects/:id/pid/*");
  const isCurrent = isNil(version);
  const showApproval =
    match("/projects/:id/brief/approve") || match("/projects/:id/pid/approve");

  const roleOrder = [
    "executive",
    "seniorUser",
    "seniorSupplier",
    "businessAssurance",
    "userAssurance",
    "supplierAssurance",
    "projectManager",
    "projectSupport",
    "changeAuthority",
    "stakeholder",
    "teamManager",
    "teamMember",
  ];

  const {
    isLoading,
    data: initialProjectData = [],
    refetch: refetchProject,
  } = useQ(`project-${id}`, () => project.single({ id }));
 
  const { data: briefHistory = [] } = useQ(`project-${id}-brief-history`, () =>
    useOptimizedProject ? project.briefHistory({ id }) : () => [],
  );
  const { data: history = [] } = useQ(`project-${id}-history`, () =>
    useOptimizedProject ? project.history({ id }) : () => [],
  );

  const projectData = isNil(version)
    ? initialProjectData
    : getVersion(
        useOptimizedProject
          ? {
              ...initialProjectData,
              briefHistory: briefHistory,
              history: history,
            }
          : initialProjectData,
        version,
        isPid,
      );
  console.log("initialProjectData", initialProjectData);
  console.log(
    "getVersion(initialProjectData, version, isPid);",
    getVersion(initialProjectData, version, isPid),
  );

  const { data: roles = [], refetch } = useQ(`project-${id}-roles`, () =>
    project.roles({ id }),
  );
  /*const { data: projectPlanData = {}, refetch: refetchProjectPlans } = useQ(
    `project-${id}-plans`,
    () => project.plans({ id }),
  );*/

  const { data: projectTypes = [], refetch: refetchprojectTypes } = useQ(
    `projectTypes`,
    () => projectType.list(),
  );

  const { data: qualityApproach = {} } = useQ(
    `project-${id}-quality-approach`,
    () => project.quality.approach({ projectId: id }),
    {
      enabled: isCurrent,
    },
  );

  const { data: riskApproach = {} } = useQ(
    `project-${id}-risk-approach`,
    () => project.riskApproach({ id }),
    {
      enabled: isCurrent,
    },
  );

  const { data: changeApproach = {} } = useQ(
    `project-${id}-change-approach`,
    () => project.changeApproach({ id }),
    {
      enabled: isCurrent,
    },
  );

  const { data: controlsData = {} } = useQ(
    `project-${id}-controls`,
    () => project.controls({ id }),
    {
      enabled: isCurrent,
    },
  );
  if (projectData === undefined) {
    return (
      <Page isLoading={isLoading} title={t("project.navigation.reports.pid")}>
        <Typography sx={{ marginTop: 4 }} variant="h2">
          {t(
            isPid
              ? "project.projectInitiationDocument.missing"
              : "project.projectBrief.missing",
          )}
        </Typography>
      </Page>
    );
  }
  return (
    <Page isLoading={isLoading} title={t("project.navigation.reports.pid")}>
      <Typography
        sx={{ textAlign: "center", fontSize: "32x", marginTop: 4 }}
        variant="h1"
      >
        <div>
          {isPid
            ? t("project.navigation.reports.pid")
            : t("project.navigation.reports.brief")}
        </div>
        <div style={{ fontSize: "80%" }}>
          {(initialProjectData ? initialProjectData.name : "") + " - "}
          <DatePretty
            format={"ll"}
            date={!isCurrent ? projectData?.date : new Date()}
          />
        </div>
      </Typography>
      {isCurrent ? (
        <div
          style={{
            color: "#212121",
            fontFamily: "Lato",
            backgroundColor: "#FFEADB",
            padding: 10,
            textAlign: "center",
          }}
        >
          {isPid
            ? projectData?.pid?.type === "draft"
              ? "Draft"
              : "Preview"
            : projectData?.brief?.type === "draft"
              ? "Draft"
              : "Preview"}
        </div>
      ) : (
        ""
      )}

      <ProjectBrief
        projectId={initialProjectData?.id}
        projectData={projectData}
        roles={isCurrent ? roles : projectData?.organization}
        version={version}
      />

      {isPid ? (
        <Stack spacing={2.5}>
          <Stack spacing={1}>
            {(
              isCurrent
                ? !isEmpty(qualityApproach)
                : projectData?.qualityManagementApproach
            ) ? (
              <Typography
                sx={{ borderBottom: "1px solid #E5E7EB" }}
                variant="h1"
              >
                {t("project.quality.approach.title")}
              </Typography>
            ) : null}
            <DisplayContent
              area="quality.approach.fields"
              data={
                isCurrent
                  ? qualityApproach
                  : projectData?.qualityManagementApproach
              }
              fields={[
                "introduction",
                "process",
                "tools",
                "records",
                "reporting",
                "timing",
                "roles",
              ]}
            />
          </Stack>
          <Stack spacing={1}>
            {(
              isCurrent
                ? riskApproach?.approach
                : projectData?.riskManagementApproach
            ) ? (
              <Typography
                sx={{ borderBottom: "1px solid #E5E7EB" }}
                variant="h1"
              >
                {t("project.risk.approach.title")}
              </Typography>
            ) : null}
            <DisplayContent
              area="risk.approach.fields"
              data={
                isCurrent
                  ? riskApproach?.approach
                  : projectData?.riskManagementApproach ?? {}
              }
              fields={[
                "process",
                "tools",
                "reporting",
                "timing",
                "roles",
                "tolerance",
                "budget",
              ]}
            />
          </Stack>
          <Stack spacing={1}>
            {(
              isCurrent
                ? changeApproach?.approach
                : projectData?.changeControlApproach
            ) ? (
              <Typography
                sx={{ borderBottom: "1px solid #E5E7EB" }}
                variant="h1"
              >
                {t("project.change.approach.title")}
              </Typography>
            ) : null}
            <DisplayContent
              area="change.approach.fields"
              data={
                isCurrent
                  ? changeApproach?.approach
                  : projectData?.changeControlApproach ?? {}
              }
              fields={[
                "process",
                "tools",
                "reporting",
                "timing",
                "roles",
                "scales",
              ]}
            />
          </Stack>

          {(
            isCurrent
              ? projectData?.communicationApproach
              : !isEmpty(projectData?.communicationManagementApproach)
          ) ? (
            <Stack spacing={1}>
              <Typography
                sx={{ borderBottom: "1px solid #E5E7EB" }}
                variant="h1"
              >
                {t("project.organization.approach.title")}
              </Typography>
              <Stack spacing={1}>
                {(
                  isCurrent
                    ? projectData?.communicationApproach?.process
                    : projectData?.communicationManagementApproach?.process
                ) ? (
                  <Typography variant="h2">
                    {t("project.organization.approach.fields.process.title")}
                  </Typography>
                ) : null}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: isCurrent
                      ? projectData?.communicationApproach?.process
                      : projectData?.communicationManagementApproach?.process,
                  }}
                />

                {(
                  isCurrent
                    ? projectData?.communicationApproach?.tools
                    : projectData?.communicationManagementApproach?.tools
                ) ? (
                  <Typography variant="h2">
                    {t("project.organization.approach.fields.tools.title")}
                  </Typography>
                ) : null}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: isCurrent
                      ? projectData?.communicationApproach?.tools
                      : projectData?.communicationManagementApproach?.tools,
                  }}
                />
                {(
                  isCurrent
                    ? projectData?.communicationApproach?.reporting
                    : projectData?.communicationManagementApproach?.reporting
                ) ? (
                  <Typography variant="h2">
                    {t("project.organization.approach.fields.reporting.title")}
                  </Typography>
                ) : null}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: isCurrent
                      ? projectData?.communicationApproach?.reporting
                      : projectData?.communicationManagementApproach?.reporting,
                  }}
                />
                {(
                  isCurrent
                    ? projectData?.communicationApproach?.timing
                    : projectData?.communicationManagementApproach?.timing
                ) ? (
                  <Typography variant="h2">
                    {t("project.organization.approach.fields.timing.title")}
                  </Typography>
                ) : null}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: isCurrent
                      ? projectData?.communicationApproach?.timing
                      : projectData?.communicationManagementApproach?.timing,
                  }}
                />
                {(
                  isCurrent
                    ? projectData?.communicationApproach?.roles
                    : projectData?.communicationManagementApproach?.roles
                ) ? (
                  <Typography variant="h2">
                    {t("project.organization.approach.fields.roles.title")}
                  </Typography>
                ) : null}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: isCurrent
                      ? projectData?.communicationApproach?.timing
                      : projectData?.communicationManagementApproach?.timing,
                  }}
                />
                {(
                  isCurrent
                    ? projectData?.stakeholderCommunication
                    : projectData?.communicationManagementApproach
                        ?.stakeholderCommunication
                ) ? (
                  <Stack>
                    <Typography variant="h2">
                      {t("Communication to stakeholders")}
                    </Typography>
                    <StakeholderForm
                      stakeholderCommunication={
                        isCurrent
                          ? projectData?.stakeholderCommunication
                          : projectData?.communicationManagementApproach
                              ?.stakeholderCommunication
                      }
                    />
                  </Stack>
                ) : null}
              </Stack>
            </Stack>
          ) : null}
          <Stack spacing={2}>
            <Typography sx={{ borderBottom: "1px solid #E5E7EB" }} variant="h1">
              {t("project.projectInitiationDocument.projectPlan.title")}
            </Typography>
            <StagePlanForm
              showSum={true}
              showContent={false}
              historicProjectPlan={isCurrent ? undefined : projectData?.plan}
            />
            {projectTypes
              .find((x) => x.id === initialProjectData?.projectType)
              ?.phases?.map((phase, index) => (
                <Typography variant="h3" fontWeight={"bold"}>
                  {phase.text}
                </Typography>
              ))}
          </Stack>
          {(isCurrent ? controlsData : projectData)?.projectControls
            ?.projectControls ? (
            <Stack spacing={1}>
              <Typography
                sx={{ borderBottom: "1px solid #E5E7EB" }}
                variant="h1"
              >
                {t("project.plans.controls.title")}
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: (isCurrent ? controlsData : projectData)
                    ?.projectControls?.projectControls,
                }}
              />
            </Stack>
          ) : null}
        </Stack>
      ) : null}
      <Box sx={{ height: "10px" }} />
      {showApproval ? <ApprovalForm id={id} isPid={isPid} /> : null}
    </Page>
  );
};
