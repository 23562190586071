import React, { memo, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { uris } from "@/config/nav";
import { useQ } from "@/hooks/useQ";
import { project } from "@/queries";
import { useFlag } from "@/hooks/useFlag";
import { isNil } from "ramda";
import { useT } from "@/hooks/useT";
import { clientUrl } from "@/util/routing";
import moment from "moment";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import { Divider } from "@mui/material";
import {
  compareReportsByDateAndDraftStatus,
  isSameDate,
  groupReportsByDate,
} from "./useHighlightReports";

import { LinkItem } from "./DrawerNavigation";
import { ToggleMenu } from "./ToggleMenu";
import { LinksNavigation } from "./LinksNavigation";
import { ActionsNavigation } from "./ActionsNavigation";
import { StandardProgressNavigation } from "./StandardProgressNavigation";
import { PidBriefNavigation } from "./PidBriefNavigation";

const ChangeStatusNavigation = memo(() => {
  const { t } = useT();
  const { id = "null" } = useParams();
  const { data: projectData = {}, refetch } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );
  const toast = useToast();
  const pauseProject = useMutation(project.pause, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project has been paused");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error pausing project");
    },
  });
  const startProject = useMutation(project.start, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project has been started");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error starting project");
    },
  });
  const stopProject = useMutation(project.stop, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project has been stoped");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error stoping projec");
    },
  });
  const prepareProjectClosure = useMutation(project.prepareClosure, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Closure has been prepared");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error preparing project closure");
    },
  });
  const abortProjectClosure = useMutation(project.abortClosure, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Closure has been aborted");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error aborting project closure");
    },
  });
  const approveClosure = useMutation(project.approveClosure, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project closed approved");
      refetch();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error approving project closure");
    },
  });

  const items: LinkItem[] = [];
  if (!projectData.isStarted) {
    items.push({
      id: "start",
      label: t("project.navigation.changeStatus.start"),
      onClick: () =>
        startProject.mutate({
          id,
          data: {},
        }),
    });
  }

  if (
    !(
      projectData.isPaused ||
      projectData.isStopped ||
      (projectData.isCompleted && !projectData.isStarted)
    )
  ) {
    items.push({
      id: "pause",
      label: t("project.navigation.changeStatus.pause"),
      onClick: () =>
        pauseProject.mutate({
          id,
          data: {},
        }),
    });
  }

  if (
    !(
      projectData.isPaused ||
      projectData.isStopped ||
      (projectData.isCompleted && !projectData.isStarted)
    )
  ) {
    items.push({
      id: "stop",
      label: t("project.navigation.changeStatus.stop"),
      onClick: () =>
        stopProject.mutate({
          id,
          data: {},
        }),
    });
  }

  if (
    !projectData.isClosing &&
    !projectData.isCompleted &&
    !!projectData.isStarted
  ) {
    items.push({
      id: "prepare",
      label: t("project.navigation.changeStatus.prepareClosure"),
      onClick: () =>
        prepareProjectClosure.mutate({
          id,
          data: {},
        }),
    });
  }

  if (!!projectData.recommendedProjectClosure) {
    items.push({
      id: "abortProjectClosure",
      label: t(
        "project.overview.activities.closeProject.actions.abortClosure.title",
      ),
      onClick: () =>
        abortProjectClosure.mutate({
          id,
          data: {},
        }),
    });
  }

  if (!!projectData.isClosing && projectData.level < 3) {
    items.push({
      id: "approveClosure",
      label: t(
        "project.overview.activities.closeProject.actions.approveClosure.title",
      ),
      onClick: () =>
        approveClosure.mutate({
          id,
        }),
    });
  }

  return (
    <ToggleMenu
      text={t("project.navigation.changeStatus.title")}
      items={items}
    />
  );
});

const ReportsNavigation = memo(() => {
  const { t } = useT();
  const { id = "null" } = useParams();

  return (
    <ToggleMenu
      data-testid="ReportsNav"
      text={t("project.navigation.reports.title")}
      items={[
        {
          id: "brief",
          label: t("project.navigation.reports.brief"),
          to: clientUrl(uris.project.brief, { id }),
        },
        {
          id: "pid",
          label: t("project.navigation.reports.pid"),
          to: clientUrl(uris.project.pid, { id }),
          minLevel: 3,
        },
      ]}
    />
  );
});

const useProjectReports = (id: string, reportId: string, type: string) => {
  const { t } = useT();
  const useNewCheckpoint = useFlag("checkpoints.useRestApiV1");
  const useOptimizedReports = useFlag("project.optimize.reports");
  const { data: checkpoints = [] } = useQ(
    `project-${id}-report-checkpoints`,
    () => project.report.checkpoints({ id }),
  );
  const { data: highlights = [], isFetched } = useQ(
    `project-${id}-report-highlights`,
    () =>
      project.report.highlights({
        id,
      }),
    { enabled: !useOptimizedReports },
  );

  const {
    data: reportList = [],
    isFetched: fetchedReports,
    isLoading,
  } = useQ(`project-${id}-report-list`, () =>
    project.report.list({
      id,
      type,
    }),
  );

  const allReports = useMemo(() => {
    return useOptimizedReports ? reportList : highlights;
  }, [useOptimizedReports, reportList, highlights]);

  const getReportsByType = useCallback(
    (type: string) => {
      if (!type) {
        return allReports.filter(
          (report) =>
            !!report.stage &&
            report.endProject !== true &&
            report.type !== "checkpoint",
        );
      }
      switch (type) {
        case "checkpoint":
          return useNewCheckpoint
            ? allReports.filter((report) => report.type === "checkpoint")
            : checkpoints;
        case "highlight":
          return allReports.filter(
            (report) =>
              !report.stage &&
              report.endProject !== true &&
              report.type !== "checkpoint",
          );
        case "endStage":
          return allReports.filter(
            (report) => !!report.stage && report.endProject !== true,
          );
        case "endProject":
          return allReports.filter((report) => report.endProject === true);
        default:
          return [];
      }
    },
    [useNewCheckpoint, isLoading, allReports, checkpoints, type, reportId],
  );

  const reports = useMemo(() => {
    const filterType = !type ? "highlight" : type;
    const filteredReports = getReportsByType(filterType);
    const groupedReports = groupReportsByDate(filteredReports);
    console.log({ filteredReports });

    return groupedReports
      .slice()
      .sort(compareReportsByDateAndDraftStatus)
      .reverse()
      .map(formatReportForDisplay);
  }, [useNewCheckpoint, isLoading, allReports, checkpoints, type, reportId]);

  function filterUniqueOrRelevantReports(report, index, allReports) {
    return (
      index === 0 ||
      !isSameDate(allReports[index - 1], report) ||
      report.id === reportId
    );
  }

  function formatReportForDisplay(report) {
    const versionLabel = report.version ? ` (v${report.version})` : "";
    const draftLabel = report.isDraft
      ? ` (${t("project.navigation.reports.draft")})`
      : "";

    return {
      name: `${report.date}${versionLabel}${draftLabel}`,
      id: report.id,
      children:
        report.children?.map((child) => formatReportForDisplay(child)) ?? [],
    };
  }

  function lastReportId(reports) {
    const lastReport = reports
      .slice()
      .sort((a, b) =>
        a.isDraft
          ? 1
          : b.isDraft
            ? -1
            : moment(a.date).isBefore(moment(b.date)) || a.to < b.to
              ? -1
              : moment(b.date).isBefore(moment(a.date)) || b.to < a.to
                ? 1
                : moment(a.timestamp).isBefore(moment(b.timestamp))
                  ? -1
                  : moment(b.timestamp).isBefore(moment(a.timestamp))
                    ? 1
                    : 0,
      )
      .slice(-1);
    return lastReport.length > 0 ? lastReport[0].id : undefined;
  }

  const lastCheckpointReportId = useNewCheckpoint
    ? lastReportId(
        reportList.filter(
          (report) => isNil(report.stage) && report.type === "checkpoint",
        ),
      ) !== undefined
      ? lastReportId(
          reportList.filter(
            (report) => isNil(report.stage) && report.type === "checkpoint",
          ),
        )
      : "noReport"
    : (isFetched || fetchedReports) && lastReportId(checkpoints) !== undefined
      ? lastReportId(checkpoints)
      : "noReport";

  const lastHighlightReportId =
    (isFetched || fetchedReports) &&
    lastReportId(getReportsByType("highlight")) !== undefined
      ? lastReportId(getReportsByType("highlight"))
      : "noReport";

  const lastEndStageReportId =
    (isFetched || fetchedReports) &&
    lastReportId(getReportsByType("endStage")) !== undefined
      ? lastReportId(getReportsByType("endStage"))
      : "noReport";

  const lastEndProjectReportId =
    (isFetched || fetchedReports) &&
    lastReportId(getReportsByType("endProject")) !== undefined
      ? lastReportId(getReportsByType("endProject"))
      : "noReport";

  return {
    reports,
    lastCheckpointReportId,
    lastHighlightReportId,
    lastEndStageReportId,
    lastEndProjectReportId,
  };
};

export const ProjectNavigation = memo(
  ({
    progressMenuOpen,
    pidBriefMenuOpen,
  }: {
    progressMenuOpen: boolean;
    pidBriefMenuOpen: boolean;
  }) => {
    const {
      id = "null",
      reportId = "noReport",
      type = "highlight",
    } = useParams();
    const { data: projectData = {}, refetch } = useQ(`project-${id}`, () =>
      project.single({ id }),
    );
    const useOptimizedProject = useFlag("project.optimize.project");

    const { data: briefHistory = [] } = useQ(
      `project-${id}-brief-history`,
      () => (useOptimizedProject ? project.briefHistory({ id }) : () => []),
    );

    const { data: history = [] } = useQ(`project-${id}-history`, () =>
      useOptimizedProject ? project.history({ id }) : () => [],
    );

    const level = projectData?.level ?? 3;
    const {
      reports,
      lastCheckpointReportId,
      lastHighlightReportId,
      lastEndStageReportId,
      lastEndProjectReportId,
    } = useProjectReports(id, reportId, type);

    console.log(
      "lastCheckpointReportId: " + lastCheckpointReportId,
      "lastHighlightReportId: " + lastHighlightReportId,
      "lastEndStageReportId: " + lastEndStageReportId,
      "lastEndProjectReportId: " + lastEndProjectReportId,
    );

    const useLog = useFlag("project.log", id);

    const items: LinkItem[] = [
      {
        id: "home",
        labelKey: "project.navigation.home",
        to: uris.project.single,
        params: { id },
        end: true,
      },
      {
        id: "plans",
        labelKey: "project.navigation.plans.title",
        to: uris.project.plans,
        params: { id },
        minLevel: 3,
        children: [
          {
            id: "plan.schedule",
            labelKey: "project.navigation.plans.schedule",
            to: uris.project.plans,
            params: { id },
            end: true,
          },
          {
            id: "plan.approach",
            labelKey: "project.navigation.plans.approach",
            to: uris.project.plansApproach,
            params: { id },
            end: true,
          },
          {
            id: "plan.lessons",
            labelKey: "project.navigation.plans.lessons",
            to: uris.project.plansLessons,
            params: { id },
            end: true,
          },
          {
            id: "plan.controls",
            labelKey: "project.navigation.plans.controls",
            to: uris.project.plansControls,
            params: { id },
            end: true,
          },
        ],
      },
      {
        id: "businessCase",
        labelKey: "project.navigation.businessCase",
        to: uris.project.businessCase,
        params: { id },
        minLevel: 3,
      },
      {
        id: "risk", // Provide a unique ID for this section
        labelKey: "project.navigation.risk.title",
        to: uris.project.risk,
        params: { id },
        minLevel: 2, // Add this if needed
        children: [
          {
            id: "risk.register",
            labelKey: "project.navigation.risk.register",
            to: uris.project.risk,
            params: { id },
            end: true,
          },
          {
            id: "risk.approach",
            labelKey: "project.navigation.risk.approach",
            to: uris.project.riskApproach,
            params: { id },
            end: true,
          },
        ],
      },
      {
        id: "organization",
        labelKey: "project.navigation.organization.title",
        to: uris.project.organization,
        params: { id },
        children: [
          {
            id: "organization.team",
            labelKey: "project.navigation.organization.team",
            to: uris.project.organization,
            params: { id },
            end: true,
          },
          {
            id: "organization.communication",
            labelKey: "project.navigation.organization.communication",
            to: uris.project.organizationApproach,
            params: { id },
            end: true,
          },
          {
            id: "organization.stakeholder",
            labelKey: "project.navigation.organization.stakeholder",
            to: uris.project.organizationStakeholderAnalysis,
            params: { id },
          },
        ],
      },
      {
        id: "change",
        labelKey: "project.navigation.change.title",
        to: uris.project.change,
        params: { id },
        minLevel: 2,
        children: [
          {
            id: "change.issueRegister",
            labelKey: "project.navigation.change.issueRegister",
            to: uris.project.change,
            params: { id },
            end: true,
          },
          {
            id: "change.approach",
            labelKey: "project.navigation.change.approach",
            to: uris.project.changeApproach,
            params: { id },
            end: true,
          },
        ],
      },
      {
        id: "quality",
        labelKey: "project.navigation.quality",
        to: uris.project.quality,
        params: { id },
        minLevel: 3,
        children: [
          {
            id: "quality.register",
            labelKey: "project.quality.register.title",
            to: uris.project.quality,
            params: { id },
            end: true,
          },
          {
            id: "quality.ppd",
            labelKey: "project.quality.ppd.title",
            to: uris.project.quality + "/productDescription",
            params: { id },
          },
          {
            id: "quality.approach",
            labelKey: "project.quality.approach.title",
            to: uris.project.quality + "/approach",
            params: { id },
          },
        ],
      },
      {
        id: "progress",
        labelKey: "project.navigation.progress",
        to: uris.project.progressFull,
        params: {
          id,
          reportId: lastHighlightReportId ?? "noReport",
          type: "highlight",
        },
        additionalMatchPaths: [uris.project.progressType + "/*"],
        minLevel: 3,
        children: [
          {
            id: "progress.checkpoint",
            labelKey: "project.progress.checkpointReport.title",
            to: uris.project.progressFull,
            additionalMatchPaths: [
              clientUrl(uris.project.progressType, {
                id,
                type: "checkpoint",
              }) + "/*",
              clientUrl(uris.project.create_progress, {
                id,
                type: "checkpoint",
              }) + "/*",
            ],
            params: {
              id,
              reportId: lastCheckpointReportId ?? "noReport",
              type: "checkpoint",
            },
            end: true,
          },
          {
            id: "progress.highlight",
            labelKey: "project.progress.highlightReport.title",
            to: uris.project.progressFull,
            additionalMatchPaths: [
              clientUrl(uris.project.progressType, {
                id,
                type: "highlight",
              }) + "/*",
              clientUrl(uris.project.create_progress, {
                id,
                type: "highlight",
              }) + "/*",
            ],
            params: {
              id,
              reportId: lastHighlightReportId,
              type: "highlight",
            },
            end: true,
          },
          {
            id: "progress.endStage",
            labelKey: "project.progress.endStage.title",
            to: uris.project.progressFull,
            additionalMatchPaths: [
              clientUrl(uris.project.progressType, {
                id,
                type: "endStage",
              }) + "/*",
              clientUrl(uris.project.create_progress, {
                id,
                type: "endStage",
              }) + "/*",
            ],
            params: {
              id,
              reportId: lastEndStageReportId,
              type: "endStage",
            },
            end: true,
          },
          {
            id: "progress.endProject",
            labelKey: "project.progress.endProject.title",
            to: uris.project.progressFull,
            additionalMatchPaths: [
              clientUrl(uris.project.progressType, {
                id,
                type: "endProject",
              }) + "/*",
              clientUrl(uris.project.create_progress, {
                id,
                type: "endProject",
              }) + "/*",
            ],
            params: {
              id,
              reportId: lastEndProjectReportId,
              type: "endProject",
            },
            end: true,
          },
        ],
      },
    ];

    return (
      <>
        <ToggleMenu
          data-testid="ProjectNav"
          text={projectData?.name ?? "Project"}
          items={items.filter((item) =>
            typeof item.minLevel !== "undefined"
              ? level >= item.minLevel
              : true,
          )}
          showFolder
        />
        <Divider sx={{ mt: 1 }} />
        <LinksNavigation initiativeType="project" />
        {progressMenuOpen ? (
          <>
            <StandardProgressNavigation
              summaryLink={uris.project.progressFull}
              fullscreenLink={uris.project.progressFullscreen}
              pdfLink={uris.project.progressReportIdPdf}
              reports={reports}
              editReportLink={uris.project.edit_progress}
              createReportLink={uris.project.create_progress}
              viewReportLink={uris.project.progressFull}
              lastCheckpointReportId={lastCheckpointReportId}
              lastHighlightReportId={lastHighlightReportId}
              lastEndStageReportId={lastEndStageReportId}
              lastEndProjectReportId={lastEndProjectReportId}
              dataTestId="ProjectProgressNav"
            />
          </>
        ) : pidBriefMenuOpen ? (
          <>
            <ReportsNavigation />
            <PidBriefNavigation
              projectData={projectData}
              refetch={refetch}
              history={useOptimizedProject ? history : projectData.history}
              briefHistory={
                useOptimizedProject ? briefHistory : projectData.briefHistory
              }
            />
          </>
        ) : (
          <>
            <ReportsNavigation />
            <ActionsNavigation
              editPropertiesLink={uris.project.editProperties}
              openLogLink={
                useLog ? uris.project.log : `/external/projects/:id/log`
              }
              editLinksLink={`/projects/:id/editLinks`}
              settingsLink={uris.project.settings}
            />
            <ChangeStatusNavigation />
          </>
        )}
      </>
    );
  },
);
