import React, { useEffect, useMemo, useState } from "react";
import { isEmpty, isNil, keys } from "ramda";
import { useLocation, matchPath, useMatch, useParams } from "react-router-dom";
import {
  portfolio_nav_items,
  progress_report_actions,
  progress_report_select,
  progress_report_views,
  projectNavItems,
  currentUser,
  uris,
  productNavItems,
  programmeNavItems,
  pid_brief_actions,
  pid_brief_view,
  reports_nav,
  pid_brief_doc_archive,
  releaseNavItems,
  knowledgeReportViews,
  knowledgeReportSelect,
  knowledgeReportActions,
  releaseReportActions,
  releaseReportViews,
  releaseReportSelect,
  productProgressReportViews,
  productProgressReportActions,
  productProgressReportSelect,
  change_project_status,
  lessons_nav,
} from "../config/nav";
import { useFlag } from "../hooks/useFlag";
import { useScreen } from "../hooks/useScreen";
import { portfolio, project, product, programme, release } from "../queries";
import { useQ } from "../hooks/useQ";
import { useT } from "../hooks/useT";
import { NavSection } from "./NavSection";
import { clientUrl } from "../util/routing";
import { Box, Divider, Stack } from "@mui/material";
import moment from "moment";

const MainNav = () => {
  const { t } = useT();
  const { isLoading, data = [] } = useQ(`portfolios`, () => portfolio.list());
  const { data: projectLessonsData = [], isFetched } = useQ(
    `project-lessons`,
    () => project.lessons(),
  );
  return (
    <>
      <NavSection {...portfolio_nav_items(data, t)} startsOpen={true} />

      <Box sx={{ mt: 2 }}>
        <NavSection {...lessons_nav(t, projectLessonsData)} startsOpen={true} />
      </Box>
    </>
  );
};

const PortfolioNav = () => {
  const { t } = useT();
  const { isLoading, data = [] } = useQ(`portfolios`, () => portfolio.list());
  const { data: projectLessonsData = [], isFetched } = useQ(
    `project-lessons`,
    () => project.lessons(),
  );
  const { id = "null" } = useParams();
  const loc = useLocation();
  const match = (path) =>
    matchPath(
      {
        path,
      },
      loc.pathname,
    );
  return (
    <>
      <NavSection {...portfolio_nav_items(data, t)} startsOpen={true} />
      <Box sx={{ mt: 2 }}>
        <NavSection {...lessons_nav(t, projectLessonsData)} startsOpen={true} />
      </Box>
    </>
  );
};
const AccountSettingsNav = () => {
  const { t } = useT();
  const { isLoading, data = [] } = useQ(`portfolios`, () => portfolio.list());
  const { id = "null" } = useParams();

  return (
    <>
      <NavSection
        label="Account Settings"
        startsOpen={true}
        items={[
          { label: "Users", to: "/accountsettings/users" },
          { label: "Properties", to: "/accountsettings/properties" },
          { label: "Project Settings", to: "/accountsettings/projectsettings" },
          { label: "Portfolios", to: "/accountsettings/portfolios" },
          { label: "Lifecycles", to: "/accountsettings/lifecycles" },
          { label: "Tailoring", to: "/accountsettings/tailoring" },
          { label: "Data Protection Agreement", to: "/accountsettings/dpa" },
        ]}
      />
    </>
  );
};

function isSameDate(previous, current) {
  return moment(previous.to || previous.date).isSame(
    moment(current.to || current.date),
    "date",
  );
}

const ProjectLinks = ({ items }) => {
  const { t } = useT();
  const { id = "null" } = useParams();
  console.log("project links", id);
  const { data: links = [] } = useQ(`project-${id}-links`, () =>
    project.links({ id }),
  );
  return (
    <NavSection
      label={t("project.navigation.links")}
      startsOpen={false}
      items={links.map((item) => ({ label: item.label, href: item.href }))}
    />
  );
};

const ProductLinks = ({ items }) => {
  const { t } = useT();
  const { id = "null" } = useParams();
  const { data: links = [] } = useQ(`product-${id}-links`, () =>
    product.links({ id }),
  );
  return (
    <NavSection
      label={t("project.navigation.links")}
      startsOpen={false}
      items={links.map((item) => ({ label: item.label, href: item.href }))}
    />
  );
};

function level2AndAbove(level) {
  return isNil(level) || level >= 2;
}

function level3AndAbove(level) {
  return isNil(level) || level >= 3;
}

const ProjectNav = () => {
  const { t } = useT();
  const loc = useLocation();
  const { id = "null", reportId, type = "highlight", version } = useParams();
  const useOptimizedReports = useFlag("project.optimize.reports");
  const useOptimizedProject = useFlag("project.optimize.project");
  console.log("[NAV] Project: " + id + " type: " + type);
  const { data: projectData = {}, refetch } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );
  const { data: history = [] } = useQ(`project-${id}-history`, () =>
    useOptimizedProject ? project.history({ id }) : () => [],
  );
  const { data: briefHistory = [] } = useQ(`project-${id}-brief-history`, () =>
    useOptimizedProject ? project.briefHistory({ id }) : () => [],
  );
  const { data: highlights = [], isFetched } = useQ(
    `project-${id}-report-highlights`,
    () =>
      project.report.highlights({
        id,
      }),
    { enabled: !useOptimizedReports },
  );

  const { data: reports = [], isFetched: fetchedReports } = useQ(
    `project-${id}-report-list`,
    () =>
      project.report.list({
        id,
      }),
    { enabled: !!useOptimizedReports },
  );

  const reportList = useMemo(() => {
    return useOptimizedReports ? reports : highlights;
  }, [useOptimizedReports, reports, highlights]);

  const useLog = useFlag("project.log", id);

  const { name = "" } = projectData;

  const latestReport = reportList
    .slice()
    .filter(
      (item) =>
        (item.stage === undefined &&
          item.endProject !== true &&
          item.type !== "checkpoint" &&
          !useOptimizedReports) ||
        (item.type === "highlight" && useOptimizedReports),
    )
    .sort((a, b) =>
      a.isDraft
        ? 1
        : b.isDraft
          ? -1
          : moment(a.date).isBefore(moment(b.date))
            ? -1
            : moment(b.date).isBefore(moment(a.date))
              ? 1
              : 0,
    )
    .slice(-1);

  const [canEdit, setCanEdit] = useState(false);

  if (latestReport.length > 0 && latestReport[0].id === reportId) {
    currentUser.then((result) => {
      //Should be id of creator of the report
      setCanEdit(result.id == result.id);
    });
  } else if (canEdit) {
    setCanEdit(false);
  }

  const isPid = matchPath(
    {
      path: uris.project.pid + "/*",
    },
    loc.pathname,
  );

  const reportSection = (
    <NavSection
      {...reports_nav(
        {
          projectId: id,
          level: projectData.level,
        },
        t,
      )}
    />
  );

  return (
    <>
      <Box data-testid="ProjectNav" />
      <NavSection
        {...projectNavItems({
          id,
          name: name,
          latestReport:
            latestReport.length > 0 ? latestReport[0].id : undefined,
          type: type,
          level: projectData.level,
          t: t,
          properties: projectData.properties,
        })}
        startsOpen={true}
        title={["md", "lg"]}
      />
      <Divider sx={{ mt: 2, mb: 2 }} />
      <ProjectLinks />
      {matchPath(
        {
          path: uris.project.single + "/*",
        },
        loc.pathname,
      ) &&
        !matchPath(
          {
            path: uris.project.progressFull,
          },
          loc.pathname,
        ) && (
          <Stack>
            {level2AndAbove(projectData.level) ? reportSection : null}
            {!(
              isPid ||
              matchPath(
                {
                  path: uris.project.brief + "/*",
                },
                loc.pathname,
              )
            ) ? (
              <>
                <NavSection
                  label={t("project.navigation.actions.title")}
                  startsOpen={true}
                  items={[
                    {
                      label: t("project.navigation.actions.editProperties"),
                      to: clientUrl(uris.project.editProperties, { id }),
                      matchUrl: uris.project.editProperties,
                    },
                    {
                      label: t("project.navigation.actions.openLog"),
                      to: useLog
                        ? clientUrl(uris.project.log, { id })
                        : "/external/projects/" + id + "/log",
                      matchUrl: clientUrl(uris.project.log, { id }),
                    },
                    {
                      label: t("project.navigation.actions.editLinks"),
                      to: "/projects/" + id + "/editLinks",
                    },
                    {
                      label: t("project.navigation.actions.settings"),
                      to: clientUrl(uris.project.settings, { id }),
                      matchUrl: clientUrl(uris.project.settings, { id }),
                    },
                  ]}
                />
                <NavSection
                  {...change_project_status(
                    { projectData, id, refetchProject: refetch },
                    t,
                  )}
                />
              </>
            ) : (
              <>
                <NavSection
                  {...pid_brief_view(
                    {
                      projectId: id,
                      type: matchPath(
                        {
                          path: uris.project.pid + "/*",
                        },
                        loc.pathname,
                      )
                        ? "pid"
                        : "brief",
                      version: version,
                    },
                    t,
                  )}
                  startsOpen={true}
                />
                <NavSection
                  {...pid_brief_actions(
                    {
                      projectId: id,
                      type: matchPath(
                        {
                          path: uris.project.pid + "/*",
                        },
                        loc.pathname,
                      )
                        ? "pid"
                        : "brief",
                      refetchNav: () => refetch(),
                      version: version,
                    },
                    t,
                  )}
                  startsOpen={true}
                />
                <NavSection
                  {...pid_brief_doc_archive(
                    {
                      projectData: projectData,
                      history: history ?? projectData.history ?? [],
                      briefHistory:
                        briefHistory ?? projectData.briefHistory ?? [],
                      projectId: id,
                      type: matchPath(
                        {
                          path: uris.project.pid + "/*",
                        },
                        loc.pathname,
                      )
                        ? "pid"
                        : "brief",
                      refetchNav: () => refetch(),
                      version: version,
                    },
                    t,
                  )}
                />
              </>
            )}
          </Stack>
        )}
    </>
  );
};

const ProductNav = () => {
  const { t } = useT();
  const { id = "null", reportId, type = "highlight" } = useParams();
  console.log("[NAV] Product: " + id + " type: " + type);
  const { data: productData = {} } = useQ(`product-${id}`, () =>
    product.single({ id }),
  );
  const { data: highlights = [] } = useQ(
    `product-${id}-report-highlights`,
    () => product.report.highlights({ id }),
  );
  const match = useMatch;

  const { name = "" } = productData;

  const latestReport = highlights
    .slice()
    .filter((item) => item.stage === undefined && item.endProject !== true)
    .sort((a, b) =>
      a.to < b.to
        ? -1
        : a.to > b.to
          ? 1
          : a.timestamp < b.timestamp
            ? -1
            : a.timestamp > b.timestamp
              ? 1
              : 0,
    )
    .slice(-1);

  return (
    <>
      <Box data-testid="ProductNav" />
      <NavSection
        {...productNavItems({
          id,
          name: name,
          latestReport:
            latestReport.length > 0 ? latestReport[0].id : undefined,
          level: productData.level,
          t: t,
        })}
        startsOpen={true}
        title={["md", "lg"]}
      />
      <Divider sx={{ mt: 2, mb: 2 }} />
      <ProductLinks />
      {match(uris.product.single + "/*") &&
        !match(uris.project.progressFull) && (
          <Stack>
            {level2AndAbove(productData.level) ? (
              <NavSection
                label={t("project.navigation.reports.title")}
                startsOpen={true}
                items={[
                  {
                    label: t("project.navigation.reports.highlightReports"),
                    to: clientUrl(uris.product.progressFull, {
                      id,
                      type: "highlight",
                      reportId: "latest",
                    }),
                  },
                ].filter((item) => !isNil(item))}
              />
            ) : null}
            <NavSection
              label={t("project.navigation.actions.title")}
              startsOpen={true}
              items={[
                {
                  label: t("project.navigation.actions.editProperties"),
                  to: clientUrl(uris.product.editProperties, {
                    id,
                  }),
                },
                {
                  label: t("project.navigation.actions.openLog"),
                  to: clientUrl(uris.product.log, { id }),
                  matchUrl: clientUrl(uris.product.log, { id }),
                },
                {
                  label: t("project.navigation.actions.editLinks"),
                  to: "/products/" + id + "/editLinks",
                },
              ]}
            />
          </Stack>
        )}
    </>
  );
};

const ReleaseNav = () => {
  const { t } = useT();
  const { id = "null", reportId, type = "highlight" } = useParams();
  console.log("[NAV] Release: " + id + " type: " + type);
  const { data: releaseData = {} } = useQ(`release-${id}`, () =>
    release.single({ id }),
  );
  const { data: highlights = [] } = useQ(
    `release-${id}-report-highlights`,
    () => release.report.highlights({ id }),
    { enabled: useFlag("release.progress", id) },
  );
  const match = useMatch;

  const { name = "" } = releaseData;

  const latestReport = highlights
    .slice()
    .filter((item) => item.stage === undefined && item.endProject !== true)
    .sort((a, b) =>
      a.to < b.to
        ? -1
        : a.to > b.to
          ? 1
          : a.timestamp < b.timestamp
            ? -1
            : a.timestamp > b.timestamp
              ? 1
              : 0,
    )
    .slice(-1);

  return (
    <>
      <Box data-testid="ProductNav" />
      <NavSection
        {...releaseNavItems({
          id,
          name: name,
          level: releaseData.level,
          reportId: latestReport[0]?.id,
          t: t,
        })}
        startsOpen={true}
        title={["md", "lg"]}
      />
      <Divider sx={{ mt: 2, mb: 2 }} />
      {match(uris.release.single + "/*") && (
        <NavSection
          label={t("project.navigation.actions.title")}
          startsOpen={true}
          items={[
            {
              label: t("project.navigation.actions.openLog"),
              to: clientUrl(uris.release.log, { id }),
              matchUrl: clientUrl(uris.release.log, { id }),
            },
          ]}
        />
      )}
    </>
  );
};

const ReleaseProgressNav = () => {
  const { t } = useT();
  const { id = "null", reportId, type = "highlight" } = useParams();

  const { data: highlights = [] } = useQ(
    `release-${id}-report-highlights`,
    () => release.report.highlights({ id }),
  );
  const reports = (
    type === "highlight" || isEmpty(type)
      ? highlights.filter(
          (report) => isNil(report.stage) && report.endProject !== true,
        )
      : []
  )
    .slice()
    .sort((a, b) =>
      a.to < b.to
        ? -1
        : a.to > b.to
          ? 1
          : a.timestamp < b.timestamp
            ? -1
            : a.timestamp > b.timestamp
              ? 1
              : 0,
    )
    .map((report, index, allReports) => ({
      to: report.to || report.date,
      date: isNil(report.to)
        ? new Date(report.date).toISOString().slice(0, 10)
        : new Date(report.to).toISOString().slice(0, 10),
      id: report.id,
      isDraft: report.isDraft,
      version:
        index > 0 && isSameDate(allReports[index - 1], report)
          ? allReports
              .slice(0, index)
              .filter((reportEntry) => isSameDate(reportEntry, report)).length +
            1
          : undefined,
    }))
    .reverse()
    .filter(
      (report, index, allReports) =>
        index == 0 ||
        !isSameDate(allReports[index - 1], report) ||
        report.id === reportId ||
        (isSameDate(allReports[index - 1], report) &&
          allReports[index - 1].id === reportId),
    )
    .map((report) => ({
      name:
        (report.version > 0
          ? report.date + " (v" + report.version + ")"
          : report.date) +
        (report.isDraft ? ` (${t("project.navigation.reports.draft")})` : ""),
      id: report.id,
    }));

  const latestReport = highlights
    .slice()
    .filter((item) => item.stage === undefined && item.endProject !== true)
    .sort((a, b) =>
      a.to < b.to
        ? -1
        : a.to > b.to
          ? 1
          : a.timestamp < b.timestamp
            ? -1
            : a.timestamp > b.timestamp
              ? 1
              : 0,
    )
    .slice(-1);

  return (
    <>
      <Box data-testid="KnowledgeNav" />
      <NavSection
        {...releaseReportViews({ releaseId: id, reportId }, t)}
        startsOpen={true}
      />
      <NavSection
        {...releaseReportActions(
          { releaseId: id, reportId, type },
          t,
          latestReport.length > 0 && latestReport[0].id === reportId,
        )}
        startsOpen={true}
      />
      <NavSection
        {...releaseReportSelect({ reports, releaseId: id, type }, t)}
        startsOpen={true}
      />
    </>
  );
};

const ProgrammeNav = () => {
  const { t } = useT();
  const { id = "null", reportId, type = "highlight" } = useParams();
  console.log("[NAV] Programme: " + id + " type: " + type);
  const { data: programmeData = {} } = useQ(`programme-${id}`, () =>
    programme.single({ id }),
  );
  /* const { data: checkpoints = [] } = useQ(
    `programme-${id}-report-checkpoints`,
    () => programme.report.checkpoints({ id }),
  );*/
  const { data: highlights = [] } = useQ(
    `programme-${id}-report-highlights`,
    () => programme.report.highlights({ id }),
  );
  const match = useMatch;
  const { name = "" } = programmeData;

  const latestReport = highlights
    .slice()
    .filter((item) => item.stage === undefined && item.endProject !== true)
    .sort((a, b) =>
      a.to < b.to
        ? -1
        : a.to > b.to
          ? 1
          : a.timestamp < b.timestamp
            ? -1
            : a.timestamp > b.timestamp
              ? 1
              : 0,
    )
    .slice(-1);

  const useProperties = useFlag("programme.properties", id);

  /*const reports = (
    type === "checkpoint"
      ? checkpoints
      : type === "highlight" || isEmpty(type)
      ? highlights.filter(
          (report) => isNil(report.stage) && report.endProject !== true,
        )
      : type === "endStage"
      ? highlights.filter(
          (report) => !isNil(report.stage) && report.endProject !== true,
        )
      : type === "endProject"
      ? highlights.filter((report) => report.endProject === true)
      : []
  )
    .slice()
    .sort((a, b) =>
      a.to < b.to
        ? -1
        : a.to > b.to
        ? 1
        : a.timestamp < b.timestamp
        ? -1
        : a.timestamp > b.timestamp
        ? 1
        : 0,
    )
    .map((report, index, allReports) => ({
      to: report.to || report.date,
      date: isNil(report.to)
        ? new Date(report.date).toISOString().slice(0, 10)
        : new Date(report.to).toISOString().slice(0, 10),
      id: report.id,
      isDraft: report.isDraft,
      version:
        index > 0 && isSameDate(allReports[index - 1], report)
          ? allReports
              .slice(0, index)
              .filter((reportEntry) => isSameDate(reportEntry, report)).length +
            1
          : undefined,
    }))
    .reverse()
    .filter(
      (report, index, allReports) =>
        index == 0 ||
        !isSameDate(allReports[index - 1], report) ||
        report.id === reportId ||
        (isSameDate(allReports[index - 1], report) &&
          allReports[index - 1].id === reportId),
    )
    .map((report) => ({
      name:
        (report.version > 0
          ? report.date + " (v" + report.version + ")"
          : report.date) + (report.isDraft ? " (draft)" : ""),
      id: report.id,
    }));*/ // reportData ?

  return (
    <>
      <Box data-testid="ProgrammeNav" />
      <NavSection
        {...programmeNavItems({
          id,
          name: name,
          latestReport:
            latestReport.length > 0 ? latestReport[0].id : undefined,
          level: programmeData.level,
          t: t,
        })}
        startsOpen={true}
        title={["md", "lg"]}
      />
      <Divider sx={{ mt: 2, mb: 2 }} />
      {useProperties ? (
        <NavSection
          label={t("project.navigation.actions.title")}
          startsOpen={true}
          items={[
            {
              label: t("project.navigation.actions.editProperties"),
              to: clientUrl(uris.programme.editProperties, { id }),
              matchUrl: clientUrl(uris.programme.editProperties, { id }),
            },
            {
              label: t("project.navigation.actions.openLog"),
              to: clientUrl(uris.programme.log, { id }),
              matchUrl: clientUrl(uris.programme.log, { id }),
            },
          ]}
        />
      ) : null}
    </>
  );
};

const KnowledgeNav = () => {
  const { t } = useT();
  const { id = "null", reportId, type = "highlight" } = useParams();
  console.log("[NAV] Programme: " + id + " type: " + type);
  const { data: highlights = [] } = useQ(
    `programme-${id}-report-highlights`,
    () => programme.report.highlights({ id }),
  );
  const reports = (
    type === "highlight" || isEmpty(type)
      ? highlights.filter(
          (report) => isNil(report.stage) && report.endProject !== true,
        )
      : type === "endStage"
        ? highlights.filter(
            (report) => !isNil(report.stage) && report.endProject !== true,
          )
        : type === "endProject"
          ? highlights.filter((report) => report.endProject === true)
          : []
  )
    .slice()
    .sort((a, b) =>
      a.to < b.to
        ? -1
        : a.to > b.to
          ? 1
          : a.timestamp < b.timestamp
            ? -1
            : a.timestamp > b.timestamp
              ? 1
              : 0,
    )
    .map((report, index, allReports) => ({
      to: report.to || report.date,
      date: isNil(report.to)
        ? new Date(report.date).toISOString().slice(0, 10)
        : new Date(report.to).toISOString().slice(0, 10),
      id: report.id,
      isDraft: report.isDraft,
      version:
        index > 0 && isSameDate(allReports[index - 1], report)
          ? allReports
              .slice(0, index)
              .filter((reportEntry) => isSameDate(reportEntry, report)).length +
            1
          : undefined,
    }))
    .reverse()
    .filter(
      (report, index, allReports) =>
        index == 0 ||
        !isSameDate(allReports[index - 1], report) ||
        report.id === reportId ||
        (isSameDate(allReports[index - 1], report) &&
          allReports[index - 1].id === reportId),
    )
    .map((report) => ({
      name:
        (report.version > 0
          ? report.date + " (v" + report.version + ")"
          : report.date) +
        (report.isDraft ? ` (${t("project.navigation.reports.draft")})` : ""),
      id: report.id,
    }));

  const latestReport = highlights
    .slice()
    .filter((item) => item.stage === undefined && item.endProject !== true)
    .sort((a, b) =>
      a.to < b.to
        ? -1
        : a.to > b.to
          ? 1
          : a.timestamp < b.timestamp
            ? -1
            : a.timestamp > b.timestamp
              ? 1
              : 0,
    )
    .slice(-1);

  return (
    <>
      <Box data-testid="KnowledgeNav" />
      <NavSection
        {...knowledgeReportViews({ programmeId: id, reportId }, t)}
        startsOpen={true}
      />
      <NavSection
        {...knowledgeReportActions(
          { programmeId: id, reportId, type },
          t,
          latestReport.length > 0 && latestReport[0].id === reportId,
        )}
        startsOpen={true}
      />
      <NavSection
        {...knowledgeReportSelect({ reports, programmeId: id, type }, t)}
        startsOpen={true}
      />
    </>
  );
};

const ProgressNav = () => {
  const { t } = useT();
  const { id = "null", reportId, type = "highlight" } = useParams();
  const useNewCheckpoint = useFlag("checkpoints.useRestApiV1");
  const useOptimizedReports = useFlag("project.optimize.reports");
  console.log("[NAV] Project: " + id + " type: " + type);
  const { data: projectData = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );
  const { data: checkpoints = [] } = useQ(
    `project-${id}-report-checkpoints`,
    () => project.report.checkpoints({ id }),
  );
  const { data: highlights = [], isFetched } = useQ(
    `project-${id}-report-highlights`,
    () =>
      project.report.highlights({
        id,
      }),
    { enabled: !useOptimizedReports },
  );

  const {
    data: reportList = [],
    isFetched: fetchedReports,
    isLoading,
  } = useQ(
    `project-${id}-report-list`,
    () =>
      project.report.list({
        id,
      }),
    { enabled: !!useOptimizedReports },
  );

  const match = useMatch;

  const { name = "" } = projectData;

  const reports = useMemo(() => {
    const filterType = isEmpty(type) ? "highlight" : type;
    return (
      useOptimizedReports
        ? reportList.filter((report) => report.type === filterType)
        : type === "checkpoint"
          ? useNewCheckpoint
            ? highlights.filter((report) => report.type === "checkpoint")
            : checkpoints
          : type === "highlight" || isEmpty(type)
            ? highlights.filter(
                (report) =>
                  isNil(report.stage) &&
                  report.endProject !== true &&
                  report.type !== "checkpoint",
              )
            : type === "endStage"
              ? highlights.filter(
                  (report) =>
                    !isNil(report.stage) && report.endProject !== true,
                )
              : type === "endProject"
                ? highlights.filter((report) => report.endProject === true)
                : []
    )
      .slice()
      .sort((a, b) =>
        a.isDraft
          ? 1
          : b.isDraft
            ? -1
            : moment(a.date).isBefore(moment(b.date))
              ? -1
              : moment(b.date).isBefore(moment(a.date))
                ? 1
                : 0,
      )
      .map((report, index, allReports) => ({
        to: report.to || report.date,
        date: isNil(report.to)
          ? new Date(report.date).toISOString().slice(0, 10)
          : new Date(report.to).toISOString().slice(0, 10),
        id: report.id,
        isDraft: report.isDraft,
        version:
          index > 0 && isSameDate(allReports[index - 1], report)
            ? allReports
                .slice(0, index)
                .filter((reportEntry) => isSameDate(reportEntry, report))
                .length + 1
            : undefined,
      }))
      .reverse()
      .filter(
        (report, index, allReports) =>
          index == 0 ||
          !isSameDate(allReports[index - 1], report) ||
          report.id === reportId ||
          (isSameDate(allReports[index - 1], report) &&
            allReports[index - 1].id === reportId),
      )
      .map((report) => ({
        name:
          (report.version > 0
            ? report.date + " (v" + report.version + ")"
            : report.date) +
          (report.isDraft ? ` (${t("project.navigation.reports.draft")})` : ""),
        id: report.id,
      })); // reportData ?
  }, [
    useNewCheckpoint,
    useOptimizedReports,
    isLoading,
    highlights,
    checkpoints,
    type,
    reportId,
  ]);

  console.log(
    "Project: " +
      id +
      ", " +
      reportId +
      " Latest report: " +
      JSON.stringify(reports[0]),
  );

  return (
    <>
      <Box data-testid="ProgressNav" />
      <NavSection
        {...projectNavItems({
          id,
          name: name,
          latestReport: reports?.[0]?.id,
          type: type,
          level: projectData.level,
          t: t,
        })}
        startsOpen={true}
        title={["md", "lg"]}
      />
      <Divider sx={{ mt: 2, mb: 2 }} />
      <ProjectLinks />
      <Stack>
        <NavSection
          {...progress_report_views({ projectId: id, reportId }, t)}
          startsOpen={true}
        />
        <NavSection
          {...progress_report_actions(
            { projectId: id, reportId, type: type },
            t,
            reports?.[0]?.id && reports?.[0]?.id === reportId,
          )}
          startsOpen={true}
        />
        <NavSection
          {...progress_report_select(
            { reports: reports, projectId: id, type: type },
            t,
          )}
          startsOpen={true}
        />
      </Stack>
    </>
  );
};

const ProductProgressNav = () => {
  const { t } = useT();
  const { id = "null", reportId, type = "highlight" } = useParams();
  console.log("[NAV] Product: " + id + " type: " + type);
  const { data: productData = {} } = useQ(`product-${id}`, () =>
    product.single({ id }),
  );
  const { data: highlights = [] } = useQ(
    `product-${id}-report-highlights`,
    () => product.report.highlights({ id }),
  );

  const { name = "" } = productData;

  const latestReport = highlights
    .slice()
    .filter((item) => item.stage === undefined && item.endProduct !== true)
    .sort((a, b) =>
      a.to < b.to
        ? -1
        : a.to > b.to
          ? 1
          : a.timestamp < b.timestamp
            ? -1
            : a.timestamp > b.timestamp
              ? 1
              : 0,
    )
    .slice(-1);

  const reports = (
    type === "highlight" || isEmpty(type)
      ? highlights.filter(
          (report) => isNil(report.stage) && report.endProject !== true,
        )
      : type === "endStage"
        ? highlights.filter(
            (report) => !isNil(report.stage) && report.endProject !== true,
          )
        : type === "endProject"
          ? highlights.filter((report) => report.endProject === true)
          : []
  )
    .slice()
    .sort((a, b) =>
      a.to < b.to
        ? -1
        : a.to > b.to
          ? 1
          : a.timestamp < b.timestamp
            ? -1
            : a.timestamp > b.timestamp
              ? 1
              : 0,
    )
    .map((report, index, allReports) => ({
      to: report.to || report.date,
      date: isNil(report.to)
        ? new Date(report.date).toISOString().slice(0, 10)
        : new Date(report.to).toISOString().slice(0, 10),
      id: report.id,
      isDraft: report.isDraft,
      version:
        index > 0 && isSameDate(allReports[index - 1], report)
          ? allReports
              .slice(0, index)
              .filter((reportEntry) => isSameDate(reportEntry, report)).length +
            1
          : undefined,
    }))
    .reverse()
    .filter(
      (report, index, allReports) =>
        index == 0 ||
        !isSameDate(allReports[index - 1], report) ||
        report.id === reportId ||
        (isSameDate(allReports[index - 1], report) &&
          allReports[index - 1].id === reportId),
    )
    .map((report) => ({
      name:
        (report.version > 0
          ? report.date + " (v" + report.version + ")"
          : report.date) +
        (report.isDraft ? ` (${t("project.navigation.reports.draft")})` : ""),
      id: report.id,
    }));

  return (
    <>
      <Box data-testid="ProgressNav" />
      <NavSection
        {...productNavItems({
          id,
          name: name,
          latestReport:
            latestReport.length > 0 ? latestReport[0].id : undefined,
          level: productData.level,
          t: t,
        })}
        startsOpen={true}
        title={["md", "lg"]}
      />
      <Divider sx={{ mt: 2, mb: 2 }} />
      <ProductLinks />
      <Stack>
        <NavSection
          {...productProgressReportViews({ productId: id, reportId }, t)}
          startsOpen={true}
        />
        <NavSection
          {...productProgressReportActions(
            { productId: id, reportId, type: type },
            t,
            latestReport.length > 0 && latestReport[0].id === reportId,
          )}
          startsOpen={true}
        />

        <NavSection
          {...productProgressReportSelect(
            { reports, productId: id, type: type },
            t,
          )}
          startsOpen={true}
        />
      </Stack>
    </>
  );
};

export const LeftNav = () => {
  const screen = useScreen();
  const loc = useLocation();
  const match = (path) =>
    matchPath(
      {
        path,
      },
      loc.pathname,
    );

  return (
    <Box className={`navigation navigation--${screen}`}>
      {match(uris.project.single) &&
        !match(uris.project.single + "/*") &&
        !match(uris.project.edit_progressFull) &&
        !match(uris.project.new) &&
        !match(uris.project.newWithPortfolioId) &&
        !match(uris.project.newWithPortfolioIdAndProgrammeId) && <ProjectNav />}
      {match(uris.project.single + "/*") &&
        !match(uris.project.progressFull) &&
        !match(uris.project.edit_progressFull) &&
        !match(uris.project.new) &&
        !match(uris.project.newWithPortfolioId) &&
        !match(uris.project.newWithPortfolioIdAndProgrammeId) && <ProjectNav />}
      {match(uris.project.progressFull) && !match(uris.project.new) && (
        <ProgressNav />
      )}
      {match(uris.project.edit_progressFull) && !match(uris.project.new) && (
        <ProgressNav />
      )}
      {match(uris.product.progressFull) && !match(uris.product.new) && (
        <ProductProgressNav />
      )}
      {match(uris.product.single) &&
        !match(uris.product.single + "/*") &&
        !match(uris.product.new) && <ProductNav />}
      {match(uris.product.single + "/*") &&
        !match(uris.product.progressFull) &&
        !match(uris.product.new) && <ProductNav />}
      {!!(match(uris.release.single) || match(uris.release.single + "/*")) && (
        <ReleaseNav />
      )}
      {match(uris.release.progress) && <ReleaseProgressNav />}
      {(match(uris.release.progressFull) || match(uris.release.editProgress)) &&
        !match(uris.programme.new) && <ReleaseProgressNav />}
      {!!(
        match(uris.programme.single) || match(uris.programme.single + "/*")
      ) && <ProgrammeNav />}
      {match(uris.programme.knowledge) && !match(uris.programme.new) && (
        <KnowledgeNav />
      )}
      {(match(uris.programme.knowledgeFull) ||
        match(uris.programme.editKnowledge)) &&
        !match(uris.programme.new) && <KnowledgeNav />}
      {!match(uris.portfolio.single) &&
        !match(uris.project.single) &&
        !match(uris.product.single) &&
        !match(uris.programme.single) &&
        !match(uris.project.single + "/*") &&
        !match("/accountsettings/*") &&
        !match(uris.product.single + "/*") &&
        !match(uris.programme.single + "/*") &&
        !match(uris.release.single + "/*") && <MainNav />}
      {match("/accountsettings/*") && <AccountSettingsNav />}
      {match(uris.portfolio.single) && <PortfolioNav />}
    </Box>
  );
};
