import { clientUrl } from "../util/routing";
import { propOr, isNil, whereEq, isEmpty, countBy } from "ramda";
import { users, project, programme, product } from "../queries";
import { useFlag } from "../hooks/useFlag";
import { useQ } from "../hooks/useQ";
import { useMutation } from "react-query";
import { useToast } from "../hooks/useToast";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import i18n from "@/i18n";
import { useMemo } from "react";

export const currentUser = users.me();
export const uris = {
  home: "/",
  myProfile: "/my-profile",
  searchResults: "/search-results/:query",
  portfolios: "/portfolios",
  information_busines_systems: "/information-busines-systems",
  development: "/development",
  lessons: "/lessons/:scope",

  login: "/login",
  signup: "/signup",
  loginCode: "/login/:code",
  logout: "/logout",

  help: {
    portfolios: "/help/portfolios",
    programs: "/help/programs",
    projects: "/help/projects",
    products: "/help/products",
  },

  new: "/add/:portfolioId",

  settings: {
    main: "/accountsettings",
    users: "/accountsettings/users",
    properties: "/accountsettings/properties",
    projectSettings: "/accountsettings/projectsettings",
    portfolios: "/accountsettings/portfolios",
    lifecycles: "/accountsettings/lifecycles",
    tailoring: "/accountsettings/tailoring",
    dpa: "/accountsettings/dpa",
  },

  project: {
    new: "/newProject/:portfolioId",
    newWithPortfolioId: "/newProject/:portfolioId",
    newWithPortfolioIdAndProgrammeId: "/newProject/:portfolioId/:programmeId",
    single: "/projects/:id",
    edit: "/projects/edit/:id",
    edit_definition: "/projects/:id/edit/definition",
    editProperties: "/projects/:id/edit/properties",
    plans: "/projects/:id/plans",
    plansStageDetails: "/projects/:id/plans/stages/:stageId",
    plansLessons: "/projects/:id/plans/lessons",
    plansControls: "/projects/:id/plans/controls",
    plansApproach: "/projects/:id/plans/approach",
    businessCase: "/projects/:id/businessCase",
    risk: "/projects/:id/risk",
    riskDetails: "/projects/:id/risk/details/:riskId",
    riskApproach: "/projects/:id/risk/approach",
    organization: "/projects/:id/organization",
    organizationApproach: "/projects/:id/organization/approach",
    organizationStakeholderAnalysis:
      "/projects/:id/organization/stakeholderAnalysis",
    change: "/projects/:id/change",
    changeDetails: "/projects/:id/change/details/:issueId",
    changeApproach: "/projects/:id/change/approach",
    quality: "/projects/:id/quality",
    qualityOutputDetails: "/projects/:id/quality/outputs/:outputId",
    progressSingle: "/projects/:id/progress",
    progressType: "/projects/:id/progress/:type",
    progressReportId: "/projects/:id/progress/:reportId",
    progressReportIdPdf: "/projects/:id/progress/:reportId/pdf",
    progressFull: "/projects/:id/progress/:type/:reportId",
    progressFullscreen: "/projects/:id/progress/:type/:reportId/fullscreen",
    edit_progressFull: "/projects/:id/progress/:type/:reportId/edit",
    edit_progress: "/projects/:id/progress/:type/:reportId/edit",
    create_progress: "/projects/:id/progress/:type/new",
    editLinks: "/projects/:id/editLinks",
    pid: "/projects/:id/pid",
    pidHistoric: "/projects/:id/pid/:version",
    approvePid: "/projects/:id/pid/approve",
    pidPDf: "/projects/:id/pid/pdf",
    pidPDFHistoric: "/projects/:id/pid/pdf/:version",
    brief: "/projects/:id/brief",
    briefHistoric: "/projects/:id/brief/:version",
    approveBrief: "/projects/:id/brief/approve",
    briefPdf: "/projects/:id/brief/pdf",
    briefPDFHistoric: "/projects/:id/brief/pdf/:version",
    log: "/projects/:id/log",
    settings: "/projects/:id/settings",
  },
  release: {
    single: "/releases/:id",
    risk: "/releases/:id/risk",
    riskDetails: "/releases/:id/risk/details/:riskId",
    plan: "/releases/:id/plan",
    change: "/releases/:id/change",
    changeDetails: "/releases/:id/change/details/:issueId",
    progressFull: "/releases/:id/progress/:type/:reportId",
    progressReportIdPdf: "/releases/:id/progress/:reportId/pdf",
    progress: "/releases/:id/progress/:type",
    createProgress: "/releases/:id/progress/:type/new",
    editProgress: "/releases/:id/progress/:type/:reportId/edit",
    log: "/releases/:id/log",
  },
  programme: {
    new: "/newProgramme/:portfolioId",
    newWithPortfolioId: "/programmes/new/:portfolioId",
    single: "/programmes/:id",
    editDefinition: "/programmes/:id/definition",
    knowledgeFull: "/programmes/:id/knowledge/:type/:reportId",
    knowledgeReportIdPdf: "/programmes/:id/knowledge/:reportId/pdf",
    knowledge: "/programmes/:id/knowledge/:type",
    knowledgeType: "/programmes/:id/knowledge/:type",
    createKnowledge: "/programmes/:id/knowledge/:type/new",
    editKnowledge: "/programmes/:id/knowledge/:type/:reportId/edit",
    change: "/programmes/:id/decisions",
    changeDetails: "/programmes/:id/decisions/details/:issueId",
    design: "/programmes/:id/design",
    changeApproach: "/programmes/:id/decisions/approach",
    risk: "/programmes/:id/risk",
    riskDetails: "/programmes/:id/risk/details/:riskId",
    riskApproach: "/programmes/:id/risk/approach",
    organization: "/programmes/:id/organization",
    editProperties: "/programmes/:id/edit/properties",
    log: "/programmes/:id/log",
  },
  product: {
    new: "/newProduct/:portfolioId",
    single: "/products/:id",
    plans: "/products/:id/plans",
    risk: "/products/:id/risk",
    riskDetails: "/products/:id/risk/details/:riskId",
    organization: "/products/:id/organization",
    change: "/products/:id/change",
    changeDetails: "/products/:id/change/details/:issueId",
    quality: "/products/:id/quality",
    qualityOutputDetails: "/products/:id/quality/outputs/:outputId",
    roadmap: "/products/:id/roadmap",
    roadmapReleaseDetails: "/products/:id/roadmap/releases/:stageId",
    editLinks: "/products/:id/editLinks",
    progressSingle: "/products/:id/progress",
    progressType: "/products/:id/progress/:type",
    progressReportId: "/products/:id/progress/:reportId",
    progressReportIdPdf: "/products/:id/progress/:reportId/pdf",
    progressFull: "/products/:id/progress/:type/:reportId",
    createProgress: "/products/:id/progress/:type/new",
    editProgress: "/products/:id/progress/:type/:reportId/edit",
    editDefinition: "/products/:id/edit/definition",
    editProperties: "/products/:id/edit/properties",
    log: "/products/:id/log",
  },
  portfolio: {
    single: "/portfolios/:id",
  },

  errors: {
    e401: "/401",
    e404: "/404",
    e500: "/500",
    e404_fallback: "*",
  },
};

export const development_nav_items = {
  label: "Development",
  className: "",
  style: {},
  icon: "plus",
  items: [
    { label: "--Start", to: clientUrl(uris.home) },
    {
      label: "--Sample Project",
      to: clientUrl(uris.project.single, {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      }),
    },
    {
      label: "--Edit Definition",
      to: clientUrl(uris.project.edit_definition, {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      }),
    },
    {
      label: "--Protfolio Details",
      to: clientUrl(uris.portfolio.single, {
        id: "da2608aa-bcc7-4689-a84a-a53e497c0224",
      }),
    },
  ],
};

export const portfolio_nav_items = (data, t) => ({
  label: t("navigation.portfolios.title"),
  className: "",
  style: {},
  icon: "folder",
  items: data.map(({ name, id }, idx) => {
    return {
      label: name,
      to: clientUrl(uris.portfolio.single, { id }),
      matchUrl: clientUrl(uris.portfolio.single, { id }) + "/*",
    };
  }),
});

function lessonCount(lessons, type) {
  const count = lessons?.lessons?.filter(
    (lesson) => lesson.type === type,
  ).length;
  return count || 0;
}

export const lessons_nav = (t, lessons) => ({
  label: t("project.navigation.plans.lessons"),
  className: "",
  style: {},
  icon: "folder",
  items: [
    {
      label:
        t("project.plans.lessons.filter.project") +
        ` (${lessonCount(lessons, "project")})`,
      to: clientUrl(uris.lessons, { scope: "project" }),
      matchUrl: clientUrl(uris.lessons, { scope: "project" }),
    },
    {
      label:
        t("project.plans.lessons.filter.portfolio") +
        ` (${lessonCount(lessons, "portfolio")})`,
      to: clientUrl(uris.lessons, { scope: "portfolio" }),
      matchUrl: clientUrl(uris.lessons, { scope: "portfolio" }),
    },
    {
      label:
        t("project.plans.lessons.filter.program") +
        ` (${lessonCount(lessons, "programme")})`,
      to: clientUrl(uris.lessons, { scope: "program" }),
      matchUrl: clientUrl(uris.lessons, { scope: "program" }),
    },
    {
      label:
        t("project.plans.lessons.filter.corporate") +
        ` (${lessonCount(lessons, "corporate")})`,
      to: clientUrl(uris.lessons, { scope: "corporate" }),
      matchUrl: clientUrl(uris.lessons, { scope: "corporate" }),
    },
  ],
});

function level2AndAbove(level) {
  return isNil(level) || level >= 2;
}

function level3AndAbove(level) {
  return isNil(level) || level >= 3;
}

function lastReportId(reports) {
  const lastReport = reports
    .slice()
    .sort((a, b) =>
      a.isDraft
        ? 1
        : b.isDraft
          ? -1
          : moment(a.date).isBefore(moment(b.date)) || a.to < b.to
            ? -1
            : moment(b.date).isBefore(moment(a.date)) || b.to < a.to
              ? 1
              : a.timestamp < b.timestamp
                ? -1
                : a.timestamp > b.timestamp
                  ? 1
                  : 0,
    )
    .slice(-1);
  return lastReport.length > 0 ? lastReport[0].id : undefined;
}

export const projectNavItems = ({
  id = "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name = ">Project Name<",
  latestReport = "noReport",
  type,
  level,
  t,
  properties,
}) => {
  const useNewCheckpoint = useFlag("checkpoints.useRestApiV1");
  const useOptimizedReports = useFlag("project.optimize.reports");
  const { data: checkpoints = [] } = useQ(
    `project-${id}-report-checkpoints`,
    () => project.report.checkpoints({ id }),
    { enabled: !useNewCheckpoint },
  );
  const { data: highlights = [], isFetched } = useQ(
    `project-${id}-report-highlights`,
    () =>
      project.report.highlights({
        id,
      }),
    { enabled: !useOptimizedReports },
  );

  const { data: reports = [], isFetched: fetchedReports } = useQ(
    `project-${id}-report-list`,
    () =>
      project.report.list({
        id,
      }),
    { enabled: !!useOptimizedReports },
  );

  const reportList = useMemo(() => {
    return useOptimizedReports ? reports : highlights;
  }, [useOptimizedReports, reports, highlights]);

  return {
    label: name,
    className: "",
    style: {},
    icon: "folder",
    items: [
      {
        label: t("project.navigation.home"),
        to: clientUrl(uris.project.single, { id }),
        matchUrl: uris.project.single,
        end: true,
      },
      level3AndAbove(level)
        ? {
            label: t("project.navigation.plans.title"),
            to: clientUrl(uris.project.plans, { id }),
            matchUrl: clientUrl(uris.project.plans, { id }) + "/*",
            children: [
              {
                label: t("project.navigation.plans.schedule"),
                to: clientUrl(uris.project.plans, { id }),
                childMatchUrl: clientUrl(uris.project.plans, { id }),
                end: true,
              },
              {
                label: t("project.navigation.plans.approach"),
                to: clientUrl(uris.project.plansApproach, { id }),
                childMatchUrl: clientUrl(uris.project.plansApproach, { id }),
                end: true,
              },
              {
                label: t("project.navigation.plans.lessons"),
                to: clientUrl(uris.project.plansLessons, { id }),
                childMatchUrl:
                  clientUrl(uris.project.plansLessons, { id }) + "/*",
              },
              {
                label: t("project.navigation.plans.controls"),
                to: clientUrl(uris.project.plansControls, { id }),
                childMatchUrl: clientUrl(uris.project.plansControls, { id }),
                end: true,
              },
            ],
          }
        : undefined,
      level3AndAbove(level)
        ? {
            label: t("project.navigation.businessCase"),
            to: clientUrl(uris.project.businessCase, { id }),
            matchUrl: clientUrl(uris.project.businessCase, { id }) + "/*",
          }
        : undefined,
      level2AndAbove(level)
        ? {
            label: t("project.navigation.risk.title"),
            to: clientUrl(uris.project.risk, { id }),
            matchUrl: clientUrl(uris.project.risk, { id }) + "/*",
            children: [
              {
                label: t("project.navigation.risk.register"),
                linkTo: clientUrl(uris.project.risk, { id }),
                childMatchUrl: [
                  uris.project.risk,
                  clientUrl(uris.project.riskDetails, {
                    id,
                    riskId: "*",
                  }),
                ],
                end: true,
              },
              {
                label: t("project.navigation.risk.approach"),
                to: clientUrl(uris.project.riskApproach, { id }),
                childMatchUrl: clientUrl(uris.project.riskApproach, { id }),
                end: true,
              },
            ],
          }
        : undefined,
      {
        label: t("project.navigation.organization.title"),
        to: clientUrl(uris.project.organization, { id }),
        matchUrl: clientUrl(uris.project.organization, { id }) + "/*",
        children: [
          {
            label: t("project.navigation.organization.team"),
            linkTo: clientUrl(uris.project.organization, { id }),
            childMatchUrl: uris.project.organization,
            end: true,
          },
          {
            label: t("project.navigation.organization.communication"),
            to: clientUrl(uris.project.organizationApproach, { id }),
            childMatchUrl: clientUrl(uris.project.organizationApproach, { id }),
            end: true,
          },
          {
            label: t("project.navigation.organization.stakeholder"),
            to: clientUrl(uris.project.organizationStakeholderAnalysis, { id }),
          },
        ],
      },
      level2AndAbove(level)
        ? {
            label: t("project.navigation.change.title"),
            to: clientUrl(uris.project.change, { id }),
            matchUrl: uris.project.change,
            children: [
              {
                label: t("project.navigation.change.issueRegister"),
                linkTo: clientUrl(uris.project.change, {
                  id,
                }),
                childMatchUrl: [
                  uris.project.change,
                  clientUrl(uris.project.changeDetails, { id, issueId: "*" }),
                ],
              },
              {
                label: t("project.navigation.change.approach"),
                to: clientUrl(uris.project.changeApproach, { id }),
              },
            ],
          }
        : undefined,
      level3AndAbove(level)
        ? {
            label: t("project.navigation.quality"),
            to: clientUrl(uris.project.quality, { id }),
            matchUrl: uris.project.quality,
            children: [
              {
                label: t("project.quality.register.title"),
                linkTo: clientUrl(uris.project.quality, { id }),
                childMatchUrl: [
                  uris.project.quality,
                  clientUrl(uris.project.qualityOutputDetails, {
                    id,
                    outputId: "*",
                  }),
                ],
                end: true,
              },
              {
                label: t("project.quality.ppd.title"),
                linkTo: clientUrl(
                  uris.project.quality + "/productDescription",
                  {
                    id,
                  },
                ),
                childMatchUrl: uris.project.quality + "/productDescription",
              },
              {
                label: t("project.quality.approach.title"),
                linkTo: clientUrl(uris.project.quality + "/approach", { id }),
                childMatchUrl: uris.project.quality + "/approach",
              },
            ],
          }
        : undefined,
      {
        label: t("project.navigation.progress"),
        to: clientUrl(uris.project.progressFull, {
          id,
          reportId: latestReport,
          type: type,
        }),
        matchUrl: uris.project.progressSingle,
        children: [
          {
            label: t("project.progress.checkpointReport.title"),
            id: "checkpoint",
            linkTo: clientUrl(uris.project.progressFull, {
              id,
              reportId: useNewCheckpoint
                ? lastReportId(
                    reportList.filter(
                      (report) =>
                        isNil(report.stage) && report.type === "checkpoint",
                    ),
                  ) !== undefined
                  ? lastReportId(
                      reportList.filter(
                        (report) =>
                          isNil(report.stage) && report.type === "checkpoint",
                      ),
                    )
                  : "noReport"
                : (isFetched || fetchedReports) &&
                    lastReportId(checkpoints) !== undefined
                  ? lastReportId(checkpoints)
                  : "noReport",
              type: "checkpoint",
            }),
            childMatchUrl:
              clientUrl(uris.project.progressType, { id, type: "checkpoint" }) +
              "/*",
          },
          {
            label: t("project.progress.highlightReport.title"),
            id: "highlight",
            linkTo: clientUrl(uris.project.progressFull, {
              id,
              reportId:
                (isFetched || fetchedReports) &&
                lastReportId(
                  reportList.filter(
                    (report) =>
                      isNil(report.stage) &&
                      report.endProject !== true &&
                      report.type !== "checkpoint",
                  ),
                ) !== undefined
                  ? lastReportId(
                      reportList.filter(
                        (report) =>
                          isNil(report.stage) &&
                          report.endProject !== true &&
                          report.type !== "checkpoint",
                      ),
                    )
                  : "noReport",
              type: "highlight",
            }),
            childMatchUrl: [
              clientUrl(uris.project.progressType, { id, type: "highlight" }) +
                "/*",
              clientUrl(uris.project.progressReportIdPdf, {
                id,
                reportId:
                  (isFetched || fetchedReports) &&
                  lastReportId(
                    reportList.filter(
                      (report) =>
                        isNil(report.stage) && report.endProject !== true,
                    ),
                  ) !== undefined
                    ? lastReportId(
                        reportList.filter(
                          (report) =>
                            isNil(report.stage) && report.endProject !== true,
                        ),
                      )
                    : "noReport",
              }) + "/*",
            ],
          },
          {
            label: t("project.progress.endStage.title"),
            id: "endStage",
            linkTo: clientUrl(uris.project.progressFull, {
              id,
              reportId:
                (isFetched || fetchedReports) &&
                lastReportId(
                  reportList.filter((report) =>
                    useOptimizedReports
                      ? report.type === "endStage"
                      : !isNil(report.stage) && report.endProject !== true,
                  ),
                ) !== undefined
                  ? lastReportId(
                      reportList.filter((report) =>
                        useOptimizedReports
                          ? report.type === "endStage"
                          : !isNil(report.stage) && report.endProject !== true,
                      ),
                    )
                  : "noReport",
              type: "endStage",
            }),
            childMatchUrl:
              clientUrl(uris.project.progressType, { id, type: "endStage" }) +
              "/*",
          },
          {
            label: t("project.progress.endProject.title"),
            id: "endProject",
            linkTo: clientUrl(uris.project.progressFull, {
              id,
              reportId:
                (isFetched || fetchedReports) &&
                lastReportId(
                  reportList.filter((report) =>
                    useOptimizedReports
                      ? report.type === "endProject"
                      : report.endProject === true,
                  ),
                ) !== undefined
                  ? lastReportId(
                      reportList.filter((report) =>
                        useOptimizedReports
                          ? report.type === "endProject"
                          : report.endProject === true,
                      ),
                    )
                  : "noReport",
              type: "endProject",
            }),
            childMatchUrl:
              clientUrl(uris.project.progressType, { id, type: "endProject" }) +
              "/*",
          },
        ].filter((item) => !isNil(item)),
      },
    ].filter((item) => !isNil(item)),
  };
};

export const productNavItems = ({
  id = "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name = ">Product Name<",
  latestReport = "noReport",
  level,
  t,
}) => {
  const { data: highlights = [], isFetched } = useQ(
    `product-${id}-report-highlights`,
    () => product.report.highlights({ id }),
  );

  const useOrganization = useFlag("product.organization", id);
  const useChange = useFlag("prodct.change", id);

  return {
    label: name,
    className: "",
    style: {},
    icon: "folder",
    items: [
      {
        label: t("project.navigation.home"),
        to: clientUrl(uris.product.single, { id }),
        end: true,
        matchUrl: uris.product.single,
      },
      level3AndAbove(level)
        ? {
            label: t("product.navigation.roadmap"),
            to: clientUrl(uris.product.roadmap, { id }),
            matchUrl: uris.product.roadmap,
          }
        : undefined,
      level2AndAbove(level)
        ? {
            label: t("project.navigation.risk.title"),
            to: clientUrl(uris.product.risk, { id }),
            matchUrl: uris.product.risk,
          }
        : undefined,
      {
        label: t("project.navigation.organization.title"),
        to: useOrganization
          ? clientUrl(uris.product.organization, { id })
          : "/external/products/" + id + "/organization",
        matchUrl: uris.product.organization,
      },
      level2AndAbove(level)
        ? {
            label: t("project.navigation.change.title"),
            to: clientUrl(uris.product.change, { id }),
            matchUrl: uris.product.change,
          }
        : undefined,
      {
        label: t("project.navigation.progress"),
        to: clientUrl(uris.product.progressFull, {
          id,
          reportId: latestReport,
          type: "highlight",
        }),
        matchUrl: uris.product.progressSingle,
        children: [
          {
            label: t("project.progress.highlightReport.title"),
            id: "highlight",
            linkTo: clientUrl(uris.product.progressFull, {
              id,
              reportId:
                isFetched &&
                lastReportId(
                  highlights.filter(
                    (report) =>
                      isNil(report.stage) && report.endProject !== true,
                  ),
                ) !== undefined
                  ? lastReportId(
                      highlights.filter(
                        (report) =>
                          isNil(report.stage) && report.endProject !== true,
                      ),
                    )
                  : "noReport",
              type: "highlight",
            }),
            childMatchUrl: [
              clientUrl(uris.product.progressType, { id, type: "highlight" }) +
                "/*",
              clientUrl(uris.product.progressReportIdPdf, {
                id,
                reportId:
                  isFetched &&
                  lastReportId(
                    highlights.filter(
                      (report) =>
                        isNil(report.stage) && report.endProject !== true,
                    ),
                  ) !== undefined
                    ? lastReportId(
                        highlights.filter(
                          (report) =>
                            isNil(report.stage) && report.endProject !== true,
                        ),
                      )
                    : "noReport",
              }) + "/*",
            ],
          },
        ].filter((item) => !isNil(item)),
      },
    ].filter((item) => !isNil(item)),
  };
};

export const releaseNavItems = ({
  id = "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name = ">Release Name<",
  level,
  reportId = "noReport",
  t,
}) => {
  const usePlan = useFlag("release.plan", id);
  const useRisk = useFlag("release.risk", id);
  const useChange = useFlag("release.change", id);
  const useProgress = useFlag("release.progress", id);
  return {
    label: name,
    className: "",
    style: {},
    icon: "folder",
    items: [
      {
        label: t("project.navigation.home"),
        to: clientUrl(uris.release.single, { id }),
        end: true,
        matchUrl: uris.release.single,
      },
      level3AndAbove(level)
        ? {
            label: t("project.navigation.plans.title"),
            to: usePlan
              ? clientUrl(uris.release.plan, { id })
              : "/external/releases/" + id + "/plan",
            matchUrl: uris.release.plan,
          }
        : undefined,
      level2AndAbove(level)
        ? {
            label: t("project.navigation.risk.title"),
            to: useRisk
              ? clientUrl(uris.release.risk, { id })
              : "/external/releases/" + id + "/risk",
            matchUrl: uris.release.risk,
          }
        : undefined,
      level2AndAbove(level)
        ? {
            label: t("project.navigation.change.title"),
            to: useChange
              ? clientUrl(uris.release.change, { id })
              : "/external/releases/" + id + "/change",
            matchUrl: uris.release.change,
          }
        : undefined,
      {
        label: t("project.navigation.progress"),
        to: useProgress
          ? clientUrl(uris.release.progressFull, {
              id,
              type: "highlight",
              reportId,
            })
          : "/external/releases/" + id + "/highlight",
        matchUrl: uris.release.progressFull,
        children: [
          {
            label: t("project.progress.highlightReport.title"),
            id: "highlight",
            linkTo: clientUrl(uris.release.progressFull, {
              id,
              reportId,
              type: "highlight",
            }),
            childMatchUrl: [
              clientUrl(uris.release.progress, {
                id,
                type: "highlight",
              }) + "/*",
              clientUrl(uris.release.progressReportIdPdf, {
                id,
                reportId: reportId,
              }),
            ],
          },
        ],
      },
    ].filter((item) => !isNil(item)),
  };
};

export const releaseReportActions = (
  { reportId = "null", releaseId, type },
  t,
  showEdit,
) => ({
  label: t("navigation.projects.progress.actions.title"),
  items: [
    ...(showEdit
      ? [
          {
            label: t("navigation.projects.progress.actions.editReport"),
            to: clientUrl(uris.release.editProgress, {
              id: releaseId,
              reportId: reportId,
              type: type,
            }),
            matchUrl: clientUrl(uris.release.editProgress, {
              id: releaseId,
              reportId: reportId,
              type: type,
            }),
          },
        ]
      : []),
    {
      label: t("navigation.projects.progress.actions.createReport"),
      to: clientUrl(uris.release.createProgress, {
        id: releaseId,
        type: type,
      }),
      matchUrl: clientUrl(uris.release.createProgress, {
        id: releaseId,
        type: type,
      }),
    },
  ],
});

export const releaseReportViews = ({ reportId = "null", releaseId }, t) => ({
  label: t("navigation.projects.progress.view.title"),
  items: [
    {
      label: t("navigation.projects.progress.view.summary"),
      to: clientUrl(uris.release.progressFull, {
        id: releaseId,
        type: "highlight",
        reportId: reportId,
      }),
      matchUrl: clientUrl(uris.release.progressFull, {
        id: releaseId,
        type: "highlight",
        reportId: reportId,
      }),
    },
    {
      label: t("navigation.projects.progress.view.fullscreen"),
      to: clientUrl(uris.release.progress, {
        id: releaseId,
        type: "highlight",
        reportId: reportId,
      }),
    },
    {
      label: t("navigation.projects.progress.view.pdf"),
      to: clientUrl(uris.release.progressReportIdPdf, {
        id: releaseId,
        reportId: reportId,
      }),
      matchUrl: clientUrl(uris.release.progressReportIdPdf, {
        id: releaseId,
        reportId: reportId,
      }),
    },
    /*
    {
      label: t("navigation.projects.progress.view.slide"),
      to: clientUrl(uris.release.progressReportIdPdf, {
        id: releaseId,
        reportId: reportId,
      }),
    },
           */
  ],
});

export const releaseReportSelect = (
  { reports = [], releaseId, reportId, type },
  t,
) => ({
  label: t("navigation.projects.progress.select.title"),
  items: reports.map((report) => {
    return {
      label: propOr("", "name", report),
      to: clientUrl(uris.release.progressFull, {
        id: releaseId,
        reportId: propOr(undefined, "id", report),
        type,
      }),
      matchUrl: [
        clientUrl(uris.release.progressFull, {
          id: releaseId,
          reportId: propOr(undefined, "id", report),
          type,
        }),
        clientUrl(uris.release.progressReportIdPdf, {
          id: releaseId,
          reportId: propOr(undefined, "id", report),
        }),
      ],
    };
  }),
});

export const programmeNavItems = ({
  id = "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name = ">Programme Name<",
  level,
  t,
  latestReport = "noReport",
}) => {
  /*const { data: checkpoints = [], checkpointFetched = isFetched } = useQ(
    `project-${id}-report-checkpoints`,
    () => programme.report.checkpoints({ id }),
  );*/
  const { data: highlights = [], isFetched } = useQ(
    `project-${id}-report-highlights`,
    () => programme.report.highlights({ id }),
  );
  const useRisk = useFlag("programme.risk", id);
  const useChange = useFlag("programme.change", id);
  const useOrganization = useFlag("programme.organization", id);

  return {
    label: name,
    className: "",
    style: {},
    icon: "folder",
    items: [
      {
        label: t("project.navigation.home"),
        to: clientUrl(uris.programme.single, { id }),
        end: true,
        matchUrl: uris.programme.single,
      },
      {
        label: t("programme.navigation.organization.title"),
        to: useOrganization
          ? clientUrl(uris.programme.organization, { id })
          : "/external/programmes/" + id + "/organization",
        matchUrl: uris.programme.organization,
      },
      {
        label: t("programme.navigation.design.title"),
        to: clientUrl(uris.programme.design, { id }),
        matchUrl: clientUrl(uris.programme.design, { id }) + "/*",
      },
      {
        label: t("programme.navigation.justification.title"),
        to: clientUrl(uris.programme.editDefinition, { id }),
      },
      {
        label: t("programme.navigation.structure.title"),
        to: clientUrl(uris.programme.editDefinition, { id }),
      },
      {
        label: t("programme.navigation.knowledge.title"),
        to: clientUrl(uris.programme.knowledgeFull, {
          id,
          reportId: latestReport,
          type: "highlight",
        }),
        matchUrl: uris.programme.knowledgeFull,
        children: [
          {
            label: t("project.progress.highlightReport.title"),
            id: "highlight",
            linkTo: clientUrl(uris.programme.knowledgeFull, {
              id,
              reportId:
                isFetched &&
                lastReportId(
                  highlights.filter(
                    (report) =>
                      isNil(report.stage) && report.endProject !== true,
                  ),
                ) !== undefined
                  ? lastReportId(
                      highlights.filter(
                        (report) =>
                          isNil(report.stage) && report.endProject !== true,
                      ),
                    )
                  : "noReport",
              type: "highlight",
            }),
            childMatchUrl: [
              clientUrl(uris.programme.knowledge, { id, type: "highlight" }) +
                "/*",
              clientUrl(uris.programme.knowledgeFull, {
                id,
                type: "highlight",
                reportId:
                  isFetched &&
                  lastReportId(
                    highlights.filter(
                      (report) =>
                        isNil(report.stage) && report.endProject !== true,
                    ),
                  ) !== undefined
                    ? lastReportId(
                        highlights.filter(
                          (report) =>
                            isNil(report.stage) && report.endProject !== true,
                        ),
                      )
                    : "noReport",
              }) + "/*",
              clientUrl(uris.programme.knowledgeReportIdPdf, {
                id,
                reportId:
                  isFetched &&
                  lastReportId(
                    highlights.filter(
                      (report) =>
                        isNil(report.stage) && report.endProject !== true,
                    ),
                  ) !== undefined
                    ? lastReportId(
                        highlights.filter(
                          (report) =>
                            isNil(report.stage) && report.endProject !== true,
                        ),
                      )
                    : "noReport",
              }) + "/*",
            ],
          },
        ].filter((item) => !isNil(item)),
      },
      {
        label: t("programme.navigation.assurance.title"),
        to: useRisk
          ? clientUrl(uris.programme.risk, { id })
          : "/external/programmes/" + id + "/risk",
        matchUrl: clientUrl(uris.programme.risk, { id }) + "/*",
        children: [
          {
            label: t("project.navigation.risk.register"),
            linkTo: clientUrl(uris.programme.risk, { id }),
            childMatchUrl: uris.programme.risk,
            end: true,
          },
          {
            label: t("project.navigation.risk.approach"),
            to: clientUrl(uris.programme.riskApproach, { id }),
            childMatchUrl: clientUrl(uris.programme.riskApproach, { id }),
            end: true,
          },
        ],
      },
      {
        label: t("programme.navigation.decisions.title"),
        to: useChange
          ? clientUrl(uris.programme.change, { id })
          : "/external/programmes/" + id + "/change",
        matchUrl: clientUrl(uris.programme.change, { id }),
        children: [
          {
            label: t("project.navigation.change.issueRegister"),
            linkTo: clientUrl(uris.programme.change, {
              id,
            }),
            childMatchUrl: clientUrl(uris.programme.change, { id }),
          },
          {
            label: t("project.navigation.change.approach"),
            to: clientUrl(uris.programme.changeApproach, { id }),
            childMatchUrl: clientUrl(uris.programme.changeApproach, { id }),
          },
        ],
      },
    ].filter((item) => !isNil(item)),
  };
};

export const progress_report_views = ({ reportId = "null", projectId }, t) => ({
  label: t("navigation.projects.progress.view.title"),
  items: [
    {
      label: t("navigation.projects.progress.view.summary"),
      to: clientUrl(uris.project.progressFull, {
        id: projectId,
        type: "highlight",
        reportId: reportId,
      }),
      matchUrl: clientUrl(uris.project.progressFull, {
        id: projectId,
        type: "highlight",
        reportId: reportId,
      }),
    },
    {
      label: t("navigation.projects.progress.view.fullscreen"),
      onClick: () => {
        window
          .open(
            clientUrl(uris.project.progressFullscreen, {
              id: projectId,
              type: "highlight",
              reportId: reportId,
            }),
            "_blank",
          )
          .focus();
      },
    },
    {
      label: t("navigation.projects.progress.view.pdf"),
      to: clientUrl(uris.project.progressReportIdPdf, {
        id: projectId,
        reportId: reportId,
      }),
      matchUrl: clientUrl(uris.project.progressReportIdPdf, {
        id: projectId,
        reportId: reportId,
      }),
    },
    /*
    {
      label: t("navigation.projects.progress.view.slide"),
      to: clientUrl(uris.project.progressReportId, {
        id: projectId,
        reportId: reportId,
      }),
    },
           */
  ],
});

export const progress_report_actions = (
  { reportId = "null", projectId, type },
  t,
  showEdit,
) => ({
  label: t("navigation.projects.progress.actions.title"),
  items: [
    ...(showEdit
      ? [
          {
            label: t("navigation.projects.progress.actions.editReport"),
            to: clientUrl(uris.project.edit_progress, {
              id: projectId,
              reportId: reportId,
              type: type,
            }),
            matchUrl: clientUrl(uris.project.edit_progress, {
              id: projectId,
              reportId: reportId,
              type: type,
            }),
          },
        ]
      : []),
    {
      label: t("navigation.projects.progress.actions.createReport"),
      to: clientUrl(uris.project.create_progress, {
        id: projectId,
        type: type,
      }),
      matchUrl: clientUrl(uris.project.create_progress, {
        id: projectId,
        type: type,
      }),
    },
  ],
});

export const change_project_status = (
  { type, projectId, refetchProject, id = "", projectData },
  t,
) => {
  const toast = useToast();

  const pauseProject = useMutation(project.pause, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project has been paused");
      refetchProject();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error pausing project");
    },
  });
  const startProject = useMutation(project.start, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project has been started");
      refetchProject();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error starting project");
    },
  });
  const stopProject = useMutation(project.stop, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project has been stoped");
      refetchProject();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error stoping projec");
    },
  });
  const prepareProjectClosure = useMutation(project.prepareClosure, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Closure has been prepared");
      refetchProject();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error preparing project closure");
    },
  });
  const abortProjectClosure = useMutation(project.abortClosure, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Closure has been aborted");
      refetchProject();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error aborting project closure");
    },
  });

  const approveClosure = useMutation(project.approveClosure, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project closed approved");
      refetchProject();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error approving project closure");
    },
  });

  return {
    label: t("project.navigation.changeStatus.title"),
    startsOpen: true,
    items: [
      projectData.isStarted
        ? undefined
        : {
            label: t("project.navigation.changeStatus.start"),
            onClick: () =>
              startProject.mutate({
                id,
              }),
          },
      projectData.isPaused || projectData.isStopped
        ? undefined
        : {
            label: t("project.navigation.changeStatus.pause"),
            onClick: () =>
              pauseProject.mutate({
                id,
              }),
          },
      projectData.isPaused || projectData.isStopped
        ? undefined
        : {
            label: t("project.navigation.changeStatus.stop"),
            onClick: () =>
              stopProject.mutate({
                id,
              }),
          },
      !projectData.isClosing &&
      !projectData.isCompleted &&
      !!projectData.isStarted
        ? {
            label: t("project.navigation.changeStatus.prepareClosure"),
            onClick: () =>
              prepareProjectClosure.mutate({
                id,
              }),
          }
        : null,
      !!projectData.recommendedProjectClosure
        ? {
            label: t(
              "project.overview.activities.closeProject.actions.abortClosure.title",
            ),
            onClick: () =>
              abortProjectClosure.mutate({
                id,
              }),
          }
        : null,
      !!projectData.isClosing && projectData.level < 3
        ? {
            label: t(
              "project.overview.activities.closeProject.actions.approveClosure.title",
            ),
            onClick: () =>
              approveClosure.mutate({
                id,
              }),
          }
        : null,
    ].filter((item) => !isNil(item)),
  };
};

export const progress_report_select = (
  { reports = [], projectId, reportId, type },
  t,
) => ({
  label: t("navigation.projects.progress.select.title"),
  items: reports.map((report) => {
    return {
      label: propOr("", "name", report),
      to: clientUrl(uris.project.progressFull, {
        id: projectId,
        reportId: propOr(undefined, "id", report),
        type,
      }),
      matchUrl: [
        clientUrl(uris.project.progressFull, {
          id: projectId,
          reportId: propOr(undefined, "id", report),
          type,
        }),
        clientUrl(uris.project.progressReportIdPdf, {
          id: projectId,
          reportId: propOr(undefined, "id", report),
        }),
      ],
    };
  }),
});

export const productProgressReportViews = (
  { reportId = "null", productId },
  t,
) => ({
  label: t("navigation.projects.progress.view.title"),
  items: [
    {
      label: t("navigation.projects.progress.view.summary"),
      to: clientUrl(uris.product.progressFull, {
        id: productId,
        type: "highlight",
        reportId: reportId,
      }),
      matchUrl: clientUrl(uris.product.progressFull, {
        id: productId,
        type: "highlight",
        reportId: reportId,
      }),
    },
    {
      label: t("navigation.projects.progress.view.fullscreen"),
      to: clientUrl(uris.product.progressReportId, {
        id: productId,
        reportId: reportId,
      }),
    },
    {
      label: t("navigation.projects.progress.view.pdf"),
      to: clientUrl(uris.product.progressReportIdPdf, {
        id: productId,
        reportId,
      }),
      matchUrl: clientUrl(uris.product.progressReportIdPdf, {
        id: productId,
        reportId,
      }),
    },
    /*
    {
      label: t("navigation.projects.progress.view.slide"),
      to: clientUrl(uris.product.progressReportId, {
        id: productId,
        reportId: reportId,
      }),
    },
           */
  ],
});

export const productProgressReportActions = (
  { reportId = "null", productId, type },
  t,
  showEdit,
) => ({
  label: t("navigation.projects.progress.actions.title"),
  items: [
    ...(showEdit
      ? [
          {
            label: t("navigation.projects.progress.actions.editReport"),
            to: clientUrl(uris.product.editProgress, {
              id: productId,
              reportId: reportId,
              type: type,
            }),
            matchUrl: clientUrl(uris.product.editProgress, {
              id: productId,
              reportId: reportId,
              type: type,
            }),
          },
        ]
      : []),
    {
      label: t("navigation.projects.progress.actions.createReport"),
      to: clientUrl(uris.product.createProgress, {
        id: productId,
        type: type,
      }),
      matchUrl: clientUrl(uris.product.createProgress, {
        id: productId,
        type: type,
      }),
    },
  ],
});

export const productProgressReportSelect = (
  { reports = [], productId, reportId, type },
  t,
) => ({
  label: t("navigation.projects.progress.select.title"),
  items: reports.map((report) => {
    return {
      label: propOr("", "name", report),
      to: clientUrl(uris.product.progressFull, {
        id: productId,
        reportId: propOr(undefined, "id", report),
        type,
      }),
      matchUrl: [
        clientUrl(uris.product.progressFull, {
          id: productId,
          reportId: propOr(undefined, "id", report),
          type,
        }),
        clientUrl(uris.product.progressReportIdPdf, {
          id: productId,
          reportId: propOr(undefined, "id", report),
        }),
      ],
    };
  }),
});

export const knowledgeReportViews = (
  { reportId = "null", programmeId },
  t,
) => ({
  label: t("navigation.projects.progress.view.title"),
  items: [
    {
      label: t("navigation.projects.progress.view.summary"),
      to: clientUrl(uris.programme.knowledgeFull, {
        id: programmeId,
        type: "highlight",
        reportId: reportId,
      }),
      matchUrl: clientUrl(uris.programme.knowledgeFull, {
        id: programmeId,
        type: "highlight",
        reportId: reportId,
      }),
    },
    {
      label: t("navigation.projects.progress.view.fullscreen"),
      to: clientUrl(uris.programme.knowledge, {
        id: programmeId,
        type: "highlight",
        reportId: reportId,
      }),
    },
    {
      label: t("navigation.projects.progress.view.pdf"),
      to: clientUrl(uris.programme.knowledgeReportIdPdf, {
        id: programmeId,
        reportId: reportId,
      }),
      matchUrl: clientUrl(uris.programme.knowledgeReportIdPdf, {
        id: programmeId,
        reportId: reportId,
      }),
    },
    /*
    {
      label: t("navigation.projects.progress.view.slide"),
      to: clientUrl(uris.programme.knowledgeReportIdPdf, {
        id: programmeId,
        reportId: reportId,
      }),
    },
           */
  ],
});

export const knowledgeReportActions = (
  { reportId = "null", programmeId, type },
  t,
  showEdit,
) => ({
  label: t("navigation.projects.progress.actions.title"),
  items: [
    ...(showEdit
      ? [
          {
            label: t("navigation.projects.progress.actions.editReport"),
            to: clientUrl(uris.programme.editKnowledge, {
              id: programmeId,
              reportId: reportId,
              type: type,
            }),
            matchUrl: clientUrl(uris.programme.editKnowledge, {
              id: programmeId,
              reportId: reportId,
              type: type,
            }),
          },
        ]
      : []),
    {
      label: t("navigation.projects.progress.actions.createReport"),
      to: clientUrl(uris.programme.createKnowledge, {
        id: programmeId,
        type: type,
      }),
      matchUrl: clientUrl(uris.programme.createKnowledge, {
        id: programmeId,
        type: type,
      }),
    },
  ],
});

export const knowledgeReportSelect = (
  { reports = [], programmeId, reportId, type },
  t,
) => ({
  label: t("navigation.projects.progress.select.title"),
  items: reports.map((report) => {
    return {
      label: propOr("", "name", report),
      to: clientUrl(uris.programme.knowledgeFull, {
        id: programmeId,
        reportId: propOr(undefined, "id", report),
        type,
      }),
      matchUrl: [
        clientUrl(uris.programme.knowledgeFull, {
          id: programmeId,
          reportId: propOr(undefined, "id", report),
          type,
        }),
        clientUrl(uris.programme.knowledgeReportIdPdf, {
          id: programmeId,
          reportId: propOr(undefined, "id", report),
        }),
      ],
    };
  }),
});

export const pid_brief_actions = (
  { type, projectId, refetchNav, version = null },
  t,
) => {
  const isPid = type === "pid";
  const toast = useToast();
  const navigate = useNavigate();
  const createDraft = useMutation(
    isPid ? project.draftPID : project.draftBrief,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Report has been drafted");
        navigate(
          isPid
            ? `/projects/${projectId}/pid/`
            : `/projects/${projectId}/brief/`,
        );
        window.scrollTo(0, 0);
        refetchNav();
      },
      onError: ({ error }) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error creating draft");
      },
    },
  );

  return {
    label: t("project.navigation.reportActions.title"),
    items: [
      {
        label: t("project.navigation.reportActions.createDraft"),
        to: clientUrl(isPid ? uris.project.pid : uris.project.brief, {
          id: projectId,
        }),
        onClick: () =>
          createDraft.mutate({
            id: projectId,
          }),
      },
      {
        label: t("project.navigation.reportActions.approve"),
        to: clientUrl(
          isPid ? uris.project.approvePid : uris.project.approveBrief,
          {
            id: projectId,
          },
        ),
        matchUrl: isPid
          ? clientUrl(uris.project.approvePid, { id: projectId })
          : clientUrl(uris.project.approveBrief, { id: projectId }),
      },
    ],
  };
};

export const pid_brief_view = ({ type, projectId, version = null }, t) => {
  const isPid = type === "pid";

  return {
    label: t("project.navigation.reportActions.view"),
    items: [
      /*
      {
        label: t("navigation.projects.progress.view.fullscreen"),
        to: clientUrl(isPid ? uris.project.pid : uris.project.brief, {
          id: projectId,
        }),
      },
      {
        label: t("project.navigation.reportActions.showTableView"),
        to: clientUrl(isPid ? uris.project.pid : uris.project.brief, {
          id: projectId,
        }),
      },
             */
      {
        label: t("navigation.projects.progress.view.pdf"),
        to: version
          ? clientUrl(
              isPid
                ? uris.project.pidPDFHistoric
                : uris.project.briefPDFHistoric,
              {
                id: projectId,
                version: version,
              },
            )
          : clientUrl(isPid ? uris.project.pidPDf : uris.project.briefPdf, {
              id: projectId,
            }),
        matchUrl: clientUrl(
          isPid ? uris.project.pidPDf : uris.project.briefPdf,
          {
            id: projectId,
          },
        ),
      },
      {
        label: t("navigation.projects.progress.view.word"),
        to: `/external/projects/${projectId}/${
          isPid ? "pid" : "brief"
        }/?version=0&fullPage=true&docx=true&lang=${
          i18n.language === "no" ? "norwegian" : "english"
        }`,
      },
      /*
      {
        label: t("project.navigation.reportActions.download"),
        to: clientUrl(isPid ? uris.project.pid : uris.project.brief, {
          id: projectId,
        }),
      },
      {
        label: t("navigation.projects.progress.view.slide"),
        to: clientUrl(isPid ? uris.project.pid : uris.project.brief, {
          id: projectId,
        }),
      },
       */
    ],
  };
};

export const pid_brief_doc_archive = ({ type, projectData, projectId, briefHistory, history }, t) => {
  const isPid = type === "pid";

  let latestBrief =
    briefHistory?.length > 0
      ? briefHistory[briefHistory?.length - 1]
      : {};
  let latestPid =
    history?.length > 0
      ? history[history?.length - 1]
      : {};

  var briefDraftIsLatest = !moment(projectData?.brief?.date).isBefore(
    projectData?.updated,
    "seconds",
  );
  var briefReportIsLatest = !moment(latestBrief?.date).isBefore(
    projectData?.updated,
    "seconds",
  );
  var pidDraftIsLatest = !moment(projectData?.pid?.date).isBefore(
    projectData?.updated,
    "seconds",
  );
  var pidReportIsLatest = !moment(latestPid?.date).isBefore(
    projectData?.updated,
    "seconds",
  );

  const reportArchive =
    pidDraftIsLatest ||
    briefDraftIsLatest ||
    pidReportIsLatest ||
    briefReportIsLatest
      ? []
      : [
          {
            label: t("project.navigation.documentArchive.current"),
            to: clientUrl(isPid ? uris.project.pid : uris.project.brief, {
              id: projectId,
            }),
            matchUrl: [
              clientUrl(isPid ? uris.project.pid : uris.project.brief, {
                id: projectId,
              }),
              clientUrl(
                isPid ? uris.project.approvePid : uris.project.approveBrief,
                {
                  id: projectId,
                },
              ),
              clientUrl(isPid ? uris.project.pidPDf : uris.project.briefPdf, {
                id: projectId,
              }),
            ],
            end: true,
          },
        ];

  const approvedReports = isPid
    ? history?.map((item, index) => ({
        label: `${item.majorVersion}.${item.minorVersion} (${new Date(item.date)
          .toISOString()
          .slice(0, 10)})`,
        to: clientUrl(uris.project.pidHistoric, {
          id: projectId,
          version: `${item?.majorVersion}.${item?.minorVersion}`,
        }),
        matchUrl: [
          clientUrl(uris.project.pidHistoric, {
            id: projectId,
            version: `${item?.majorVersion}.${item?.minorVersion}`,
          }),
          clientUrl(uris.project.pidPDFHistoric, {
            id: projectId,
            version: `${item?.majorVersion}.${item?.minorVersion}`,
          }),
          pidReportIsLatest && index === history.length - 1
            ? clientUrl(uris.project.pid, {
                id: projectId,
                version: `${item?.majorVersion}.${item?.minorVersion}`,
              })
            : clientUrl(uris.project.pidHistoric, {
                id: projectId,
                version: `${item?.majorVersion}.${item?.minorVersion}`,
              }),
        ],
        end: true,
      }))
    : briefHistory?.map((item, index) => ({
        label: `1.${item.version} (${new Date(item.date)
          .toISOString()
          .slice(0, 10)})`,
        to: clientUrl(uris.project.briefHistoric, {
          id: projectId,
          version: `1.${item?.version}`,
        }),
        matchUrl: [
          clientUrl(uris.project.briefHistoric, {
            id: projectId,
            version: `1.${item?.version}`,
          }),
          clientUrl(uris.project.briefPDFHistoric, {
            id: projectId,
            version: `1.${item?.version}`,
          }),
          briefReportIsLatest && index === briefHistory?.length - 1
            ? clientUrl(uris.project.brief, {
                id: projectId,
                version: `1.${item?.version}`,
              })
            : clientUrl(uris.project.briefHistoric, {
                id: projectId,
                version: `1.${item?.version}`,
              }),
        ],
        end: true,
      }));

  if (!isNil(approvedReports)) reportArchive.push(...approvedReports.reverse());

  if (isPid && projectData?.pid?.type === "draft") {
    reportArchive.splice(pidDraftIsLatest || briefDraftIsLatest ? 0 : 1, 0, {
      label: `${projectData?.pid?.majorVersion}.${
        projectData?.pid?.minorVersion
      } draft (${new Date(projectData?.pid?.date).toISOString().slice(0, 10)})`,
      to: clientUrl(uris.project.pidHistoric, {
        id: projectId,
        version: `${projectData?.pid?.majorVersion}.${projectData?.pid?.minorVersion}`,
      }),
      matchUrl: [
        clientUrl(uris.project.pidPDFHistoric, {
          id: projectId,
          version: `${projectData?.pid?.majorVersion}.${projectData?.pid?.minorVersion}`,
        }),
        clientUrl(uris.project.pidHistoric, {
          id: projectId,
          version: `${projectData?.pid?.majorVersion}.${projectData?.pid?.minorVersion}`,
        }),
        pidDraftIsLatest
          ? clientUrl(uris.project.pid, {
              id: projectId,
              version: `${projectData?.pid?.majorVersion}.${projectData?.pid?.minorVersion}`,
            })
          : clientUrl(uris.project.pidHistoric, {
              id: projectId,
              version: `${projectData?.pid?.majorVersion}.${projectData?.pid?.minorVersion}`,
            }),
      ],
      end: true,
    });
  }
  if (!isPid && projectData?.brief?.type === "draft") {
    reportArchive.splice(pidDraftIsLatest || briefDraftIsLatest ? 0 : 1, 0, {
      label: `1.${projectData?.brief?.version} draft (${new Date(
        projectData?.brief?.date,
      )
        .toISOString()
        .slice(0, 10)})`,
      to: clientUrl(uris.project.briefHistoric, {
        id: projectId,
        version: `1.${projectData?.brief?.version}`,
      }),
      matchUrl: [
        clientUrl(uris.project.briefPDFHistoric, {
          id: projectId,
          version: `1.${projectData?.brief?.version}`,
        }),
        clientUrl(uris.project.briefHistoric, {
          id: projectId,
          version: `1.${projectData?.brief?.version}`,
        }),
        briefDraftIsLatest
          ? clientUrl(uris.project.brief, {
              id: projectId,
              version: `1.${projectData?.brief?.version}`,
            })
          : clientUrl(uris.project.briefHistoric, {
              id: projectId,
              version: `1.${projectData?.brief?.version}`,
            }),
      ],
      end: true,
    });
  }
  return {
    label: t("project.navigation.documentArchive.title"),
    startsOpen: true,
    items: reportArchive,
  };
};

export const reports_nav = ({ projectId, level }, t) => {
  return {
    label: t("project.navigation.reports.title"),
    startsOpen: true,
    items: [
      {
        label: t("project.navigation.reports.brief"),
        to: clientUrl(uris.project.brief, { id: projectId }),
        matchUrl: clientUrl(uris.project.brief, { id: projectId }),
      },
      level3AndAbove(level)
        ? {
            label: t("project.navigation.reports.pid"),
            to: "/projects/" + projectId + "/pid/",
            matchUrl: clientUrl(uris.project.pid, { id: projectId }),
          }
        : undefined,
    ].filter((item) => !isNil(item)),
  };
};

export const help_nav_items = {
  label: "Learn About",
  icon: null,
  items: [
    { label: "Portfolios", to: uris.help.portfolios },
    { label: "Programs", to: uris.help.programs },
    { label: "Projects", to: uris.help.projects },
    { label: "Products", to: uris.help.products },
  ],
};
